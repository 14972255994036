import { FC, memo, useContext } from "react";
import styled from "styled-components";
import { CountdownTimerContext } from "../../components";
import { OverlayContainer } from "../../components/OverlayContainer";
import { MatchStatus } from "../../service";

export const Timer: FC<{ status: MatchStatus }> = memo(({ status }) => {
  const { minutes, seconds } = useContext(CountdownTimerContext);

  return (
    <OverlayContainer>
      <TimerContainer>
        {0 <= status.pc && status.pc <= 4 && <span>Q{status.pc}</span>}
        <span>{minutes}</span>
        <span>:</span>
        <span>{seconds}</span>
      </TimerContainer>
    </OverlayContainer>
  );
});

const TimerContainer = styled.div`
  margin: 0 auto auto auto;
  background: #5e4e31;
  color: #dcc08c;
  padding: 0.4vw;

  & > span:first-child {
    padding-right: 0.4vw;
  }
`;
