import { OverlayContainer } from "components";
import { FC, memo, useCallback, useEffect, useState } from "react";
import { MatchStatus } from "service";
import styled from "styled-components";
import { SIDE } from "_constants";
import { ReactComponent as ShotOffTarget } from "../assets/away-shot-off-target.svg";
import { ReactComponent as ShotOnTarget } from "../assets/away-shot-on-target.svg";

export const ShotOnOrOffTarget: FC<{ status: MatchStatus }> = memo(
  ({ status: { nsc, side } }) => {
    const [{ toggle, active }, setState] = useState({
      toggle: false,
      active: false,
    });

    const run = useCallback(() => {}, []);

    useEffect(() => {
      if (nsc === "1011" || nsc === "1012") {
        setState((state) => ({ ...state, active: true }));

        setInterval(() => {
          setState((state) => ({ ...state, toggle: true }));
        }, 100);
      } else {
        setState((state) => ({ ...state, toggle: false, active: false }));
      }
    }, [nsc, run]);

    if (!active) return <></>;
    return (
      <OverlayContainer>
        <Container className={toggle ? "move" : ""} side={side}>
          {nsc === "1011" && <ShotOnTarget />}
          {nsc === "1012" && <ShotOffTarget />}
        </Container>
      </OverlayContainer>
    );
  }
);

const Container = styled.div<{ side?: SIDE }>`
  margin: auto 2vw;
  ${(props) => (props.side === SIDE.HOME ? "margin-left:auto !important;" : "")}

  svg {
    overflow: visible;
    width: 10vw;
    height: auto;
    ${(props) => (props.side === SIDE.HOME ? "transform: scale(-1, 1);" : "")}
  }

  &.move {
    #crosshair {
      transform: translate(0, 0) !important;
      transition: transform 300ms ease;
    }
  }
`;
