import {
  createContext,
  FC,
  memo,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";

type CountdownTimerProps = {
  startTime: number;
  countDown?: boolean;
};

type CountdownTimerContextType = {
  isPlaying: boolean;
  time: number;
  minutes: string;
  seconds: string;
  pauseTimer: () => any;
  continueTimer: () => any;
  setDirection: (direction: 1 | -1) => any;
};

export const CountdownTimerContext = createContext<CountdownTimerContextType>({
  isPlaying: false,
  time: 0,
  minutes: "",
  seconds: "",
  pauseTimer: () => undefined,
  continueTimer: () => undefined,
  setDirection: (direction: 1 | -1) => undefined,
});

export const CountdownTimerProvider: FC<CountdownTimerProps> = memo(
  ({ startTime, countDown = true, children }) => {
    const [{ time, isPlaying, direction }, setState] = useState({
      time: startTime,
      isPlaying: false,
      direction: countDown ? -1 : +1,
    });

    useEffect(() => {
      setState((state) => {
        if (Math.abs(state.time - startTime) >= 4)
          return { ...state, time: startTime };
        else return state;
      });
    }, [startTime]);

    useEffect(() => {
      const tick = () => {
        setState((state) => {
          let _time = state.time;
          if (state.isPlaying) {
            _time = state.time ? state.time + direction : 0;
          }
          return { ...state, time: _time };
        });
      };

      const interval = setInterval(tick, 1000);

      return () => {
        clearInterval(interval);
      };
    }, [isPlaying, time, countDown, direction]);

    const pauseTimer = useCallback(() => {
      setState((state) => ({ ...state, isPlaying: false }));
    }, []);

    const continueTimer = useCallback(() => {
      setState((state) => ({ ...state, isPlaying: true }));
    }, []);

    const setDirection = useCallback((direction: 1 | -1) => {
      setState((state) => ({ ...state, direction }));
    }, []);

    const [minutes, seconds] = convertSeconds(time);

    return (
      <CountdownTimerContext.Provider
        value={{
          time,
          isPlaying,
          minutes,
          seconds,
          pauseTimer,
          continueTimer,
          setDirection,
        }}
      >
        {children}
      </CountdownTimerContext.Provider>
    );
  }
);

const convertSeconds = (_seconds: number) => {
  const minutes = Math.floor(_seconds / 60);
  const seconds = _seconds - minutes * 60;
  return [
    minutes.toString().padStart(2, "0"),
    seconds.toString().padStart(2, "0"),
  ];
};

export const TimerController: FC<{
  eventsPause: string[];
  eventsContinue: string[];
  event?: string;
}> = ({ eventsContinue, eventsPause, event }) => {
  const { pauseTimer, continueTimer } = useContext(CountdownTimerContext);

  useEffect(() => {
    if (!event) return;
    if (eventsContinue.includes(event)) continueTimer();
    if (eventsPause.includes(event)) pauseTimer();
  }, [eventsContinue, eventsPause, pauseTimer, continueTimer, event]);
  return <></>;
};
