import { MatchStatus } from "./../../service/index";
import { EVENTS, MESSAGES } from "./constants";
import { LANGUAGE } from "_constants";
import { FoulIcon, TimeoutIcon } from "../../assets";

export const checkIsAway = (status: MatchStatus) => {
  if (!status.sc || status.sc.length === 4) return undefined;
  if (status.sc.length === 5) {
    if (status.sc.startsWith("2")) return true;
    else if (status.sc.startsWith("1")) return false;
    else if (status.sc.startsWith("0")) return undefined;
  }
};
export const getActiveTeamName = (status: MatchStatus) => {
  const isAway = checkIsAway(status);
  if (isAway === undefined) return undefined;
  else return isAway ? status.T2n : status.T1n;
};

export const getDescription = (status: MatchStatus) => {
  if (status.nsc === "1078") {
    const [a, b] = status.sts
      .split("|")
      .find((x) => x.startsWith("INFO="))
      ?.split("INFO=")[1]
      .split(":") || [undefined, undefined];
    if (a && b) return `${a} of ${b}`;
  }
};

export const getIcon = (normalizedStatusCode?: string) => {
  if (!normalizedStatusCode) return undefined;
  switch (normalizedStatusCode) {
    case EVENTS["Time Out"]:
      return TimeoutIcon;
    case EVENTS["Foul"]:
      return FoulIcon;
  }
};

export const processStatus = (status: MatchStatus, language: LANGUAGE) => {
  const isAway = checkIsAway(status);
  const teamName = getActiveTeamName(status);
  const normalizedStatusCode = status.sc?.substr(
    status?.sc?.length - 4,
    4
  ) as EVENTS;

  const messageDict = MESSAGES[language] || EVENTS;

  const message = normalizedStatusCode
    ? messageDict[normalizedStatusCode]
    : undefined;
  const description = getDescription(status);
  const icon = getIcon(normalizedStatusCode);

  return {
    isAway,
    teamName,
    normalizedStatusCode,
    message,
    description,
    icon,
  };
};
