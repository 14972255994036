import { FC, memo, useContext } from "react";
import styled from "styled-components";
import { CountdownTimerContext } from "../../components";
import { OverlayContainer } from "../../components/OverlayContainer";
import { MatchStatus } from "../../service";

export const Timer: FC<{ status: MatchStatus }> = memo(({ status }) => {
  const { minutes, seconds } = useContext(CountdownTimerContext);

  return (
    <OverlayContainer>
      <TimerContainer>
        <span>{minutes}</span>
        <span>:</span>
        <span>{seconds}</span>
      </TimerContainer>
    </OverlayContainer>
  );
});

const TimerContainer = styled.div`
  margin: 0 auto auto auto;
  background: #537ba3;
  color: white;
  padding: 0.4vw 2vw;
  margin-top: 0.2vw;
`;
