import styled from "styled-components";

export const OverlayContainer = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 100;
  /* padding: calc(0.5vw + 0.5px); */
  display: flex;
`;
