import { FC } from "react";
import styled from "styled-components";
import { OverlayContainer } from "components/OverlayContainer";

export const Rally: FC = ({ children }) => {
  return (
    <OverlayContainer>
      <Container>
        <RallyText>{children}</RallyText>
      </Container>
    </OverlayContainer>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 5vw;
  width: 100%;
  height: 100%;
`;

const RallyText = styled.span`
  background: #e5ad9f;
  color: #945843;
  font-size: 2vw;
  padding: 0.7vw;
  margin: 0 auto auto auto;
  border-radius: 0.4vw;
`;
