import { FC } from "react";
import { SIDE } from "_constants";
import { ReactComponent as Ball } from "../assets/ball.svg";
import styled from "styled-components";

export const ServiceAnimation: FC<{ side: SIDE; className?: string }> = ({
  side,
  className,
}) => {
  return (
    <Container side={side} className={className}>
      <Ball1 side={side} className="ball-1" />
      <Ball2 side={side} className="ball-2" />
      <Ball3 side={side} className="ball-3" />
    </Container>
  );
};

const Container = styled.div<{ side: SIDE }>`
  margin-bottom: 2vw;
  flex-grow: 1;
  margin-top: 0vw;
  margin-left: ${(props) => {
    return props.side === SIDE.AWAY ? "auto" : "2vw";
  }};

  margin-right: ${(props) => {
    return props.side === SIDE.AWAY ? "2vw" : "auto";
  }};

  @keyframes service {
    0% {
      opacity: 1;
    }
    20% {
      opacity: 0.4;
    }

    40% {
      opacity: 0.1;
    }

    60% {
      opacity: 0;
    }
    80% {
      opacity: 0;
    }

    100% {
      opacity: 0;
    }
  }

  .ball-1,
  .ball-2,
  .ball-3 {
    animation-duration: 1.3s;
    animation-direction: normal;
    animation-iteration-count: infinite;
    animation-name: service;
  }

  .ball-2 {
    animation-delay: 300ms;
  }

  .ball-3 {
    animation-delay: 600ms;
  }
`;

const WIDTH = 5;
const Ball1 = styled(Ball)<{ side: SIDE }>`
  width: ${WIDTH}vw;
  height: auto;
  position: absolute;
  bottom: 0;
  ${({ side }) => (side === SIDE.AWAY ? "right" : "left")}: 0;
`;

const Ball2 = styled(Ball1)<{ side: SIDE }>`
  transform: translate(
    ${({ side }) => (side === SIDE.HOME ? "+" : "-")}${1 * WIDTH}vw,
    -${1.5 * WIDTH}vw
  );
  opacity: 0;
`;

const Ball3 = styled(Ball1)<{ side: SIDE }>`
  transform: translate(
    ${({ side }) => (side === SIDE.HOME ? "+" : "-")}${3 * WIDTH}vw,
    -${2.2 * WIDTH}vw
  );
  opacity: 0;
`;
