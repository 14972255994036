import { ReactComponent as Court } from "./assets/court.svg";
import * as service from "../../service";
import React, { FC, memo, useCallback } from "react";
import styled from "styled-components";
import { Overlay } from "../../components/Overlay";
import { LANGUAGE } from "_constants";
import { MESSAGES } from "./constants";
import { VolleyballStatusRenderer } from "./StatusRenderer";

export type VolleyProps = {
  status: service.MatchStatus;
  language?: LANGUAGE;
};

export const Volleyball: FC<VolleyProps> = memo(
  ({ status, language = LANGUAGE.ENGLISH }) => {
    const messageGenerator = useCallback(
      ({
        language,
        normalizedStatusCode,
        statusCode,
      }: {
        language: LANGUAGE;
        normalizedStatusCode?: string;
        statusCode?: string;
      }) => {
        if (!normalizedStatusCode) return undefined;
        const langDict = MESSAGES[language];
        if (!langDict) return undefined;
        return { message: langDict[normalizedStatusCode] };
      },
      []
    );
    return (
      <>
        <StyledCourt className={`sc-${status.sc} `} />
        <Overlay
          status={status}
          messageGenerator={messageGenerator}
          language={language}
          StatusRenderer={VolleyballStatusRenderer}
        ></Overlay>
      </>
    );
  }
);

const StyledCourt = styled(Court)`
  width: 100%;
  height: 100%;

  @keyframes blinker {
    50% {
      opacity: 0;
    }
  }

  &.sc-11249 #rally-home,
  &.sc-11249 #rally-away,
  &.sc-21249 #rally-home,
  &.sc-21249 #rally-away {
    opacity: 0.35;
  }

  &.sc-21249 #rally-away {
    animation-delay: 1s;
  }

  & #rally-home,
  & #rally-away {
    animation: blinker 2s linear infinite;
  }
`;
