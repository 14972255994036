import { FC, memo } from "react";
import { MatchStatus } from "service";
import styled from "styled-components";

export const OffsideIndicator: FC<{ status: MatchStatus }> = memo(
  ({ status: { x, y, sc, nsc, side } }) => {
    if (!x || !nsc || nsc !== "1234") return <></>;
    return (
      <>
        <PathContainer>
          <svg width="400px" height="180px" viewBox="0 0 400 180">
            <g>
              <line x1={x * 400} y1="0" x2={x * 400} y2="180" />
            </g>
          </svg>
        </PathContainer>
      </>
    );
  }
);

const PathContainer = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  left: 0;

  & > svg {
    width: 100%;
    height: 100%;
  }

  & line {
    stroke-width: 2px;
    stroke: white;
  }
`;
