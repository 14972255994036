export enum LANGUAGE {
  ENGLISH = 0,
  ALBANIAN = 1,
}

export enum SIDE {
  NO_SIDE = 0,
  HOME = 1,
  AWAY = 2,
}
