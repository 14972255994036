import {
  ComponentType,
  FC,
  memo,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import styled, { CSSProperties } from "styled-components";
import { OverlayContainer } from "../../components/OverlayContainer";
import { EVENTS } from "./constants";

type StatusMessageProps = {
  className?: string;
  team?: string;
  isAway?: boolean;
  Icon?: ComponentType<any>;
  title: string;
  description?: string;
  normalizedCode: EVENTS;
  styleForStatus?: (
    normalizedCode: EVENTS,
    isAway: boolean | undefined
  ) => CSSProperties | undefined;
};

export const StatusMessage: FC<StatusMessageProps> = memo(
  ({
    team: teamName,
    isAway,
    Icon,
    title,
    description,
    className,
    normalizedCode,
    styleForStatus,
  }) => {
    const side = isAway ? "away" : "home";
    const [{ width, height }, setState] = useState({ width: 0, height: 0 });
    const overlayRef = useRef<HTMLDivElement>(null);
    const messageContainerRef = useRef<HTMLDivElement>(null);

    useLayoutEffect(() => {
      const listener = () => {
        setState((state) => ({
          ...state,
          width: overlayRef.current?.clientWidth || 0,
          height: overlayRef.current?.clientHeight || 0,
        }));
      };
      window.addEventListener("resize", listener);
      listener();
      return () => {
        window.removeEventListener("resize", listener);
      };
    }, []);

    if (!teamName)
      return (
        <OverlayContainer>
          <CenteredStatus>{title}</CenteredStatus>
        </OverlayContainer>
      );

    const style = styleForStatus && styleForStatus(normalizedCode, isAway);

    return (
      <OverlayContainer ref={overlayRef} className="overlay-container">
        <MessageContainer
          overlayWidth={width}
          overlayHeight={height}
          ref={messageContainerRef}
          className={`status-message-container nsc-${normalizedCode} ${className}`}
          data-side={side}
          style={style}
        >
          <div className="d-flex flex-column inner-container my-auto">
            <TeamName className="team-name">{teamName}</TeamName>
            <Status className="status-name">{title}</Status>
            {description && (
              <StatusDescription className="status-description">
                {description}
              </StatusDescription>
            )}
          </div>
          {Icon && (
            <IconWrapper className="icon-container">
              <Icon />
            </IconWrapper>
          )}
        </MessageContainer>
      </OverlayContainer>
    );
  }
);

const HOME_COLOR = "red";
const AWAY_COLOR = "blue";

type MessageContainerProps = {
  overlayWidth: number;
  overlayHeight: number;
};

const MessageContainer = styled.div<MessageContainerProps>`
  display: flex;
  margin: auto;

  &[data-side="home"] {
    .inner-container {
      border-right: 0.5vw solid ${HOME_COLOR};
      padding-right: 1vw;
    }

    flex-direction: row;
  }

  &[data-side="away"] {
    .inner-container {
      border-left: 0.5vw solid ${AWAY_COLOR};
      padding-left: 1vw;
    }
    flex-direction: row-reverse;
  }

  &[data-side="home"] > * {
    margin-left: auto;
  }

  &[data-side="away"] > * {
    margin-right: auto;
  }

  @keyframes pulse {
    0% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.7);
    }

    70% {
      transform: scale(1);
      box-shadow: 0 0 0 5vw rgba(255, 255, 255, 0);
    }

    100% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
    }
  }

  &.nsc-1078::after,
  &.nsc-1078::before {
    width: 4vw;
    height: 4vw;
    background: white;
    border-radius: 100%;
    z-index: 1;
    opacity: 0.7;
    margin: auto 0;
    position: absolute;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
    transform: scale(1);
    animation: pulse 0.5s infinite;
    top: calc(50% - 2vw);
  }

  &.nsc-1078[data-side="home"]::after {
    content: "";
    margin-left: 1vw;
    right: 17.48vw;
  }

  &.nsc-1078[data-side="away"]::before {
    content: "";
    margin-right: 1vw;
    left: 17.45vw;
  }

  &.nsc-1078,
  &.nsc-1079,
  &.nsc-1080 {
    &[data-side="home"] {
      margin-right: 22vw;
    }
  }

  &.nsc-1078,
  &.nsc-1079,
  &.nsc-1080 {
    &[data-side="away"] {
      margin-left: 22vw;
    }
  }
`;
const TeamName = styled.span`
  font-size: 2vw;
  color: #4c4231;
`;
const Status = styled.span`
  font-size: 4vw;
  color: white;
`;

const StatusDescription = styled.span`
  font-size: 4vw;
  color: white;
`;

const IconWrapper = styled.div`
  margin: auto;
  padding: 0 1vw;

  svg {
    height: 8vw !important;
    width: auto !important;
  }
`;

const CenteredStatus = styled.span`
  color: white;
  font-size: 4vw;
  margin: auto;
  z-index: 9999;
`;
