import {
  ComponentType,
  FC,
  memo,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import styled from "styled-components";
import { OverlayContainer } from "./OverlayContainer";
import { LANGUAGE, SIDE } from "_constants";
import { MatchStatus } from "../service";
import { getSide } from "utils/get-side";
import { getNormalizedStatusCode } from "utils/get-normalized-status-code";
import ResizeObserver from "resize-observer-polyfill";

export type StatusRendererProps = {
  homeTeam: string;
  awayTeam: string;
  activeTeam?: string;
  side: SIDE;
  code?: string;
  normalizedCode?: string;
  message?: string;
  status: MatchStatus;
  language: LANGUAGE;
  description?: string;
  Icon?: ComponentType<any>;
  width: number;
  height: number;
};

type GeneratorProps = {
  language: LANGUAGE;
  normalizedStatusCode?: string;
  statusCode?: string;
  statusMessage?: string;
};
type OverlayProps = {
  className?: string;
  status: MatchStatus;
  StatusRenderer?: ComponentType<StatusRendererProps>;
  language?: LANGUAGE;
  messageGenerator: (
    props: GeneratorProps
  ) =>
    | { message: string; description?: string; icon?: ComponentType<any> }
    | undefined;
};

export const Overlay: FC<OverlayProps> = memo(
  ({
    className = "",
    StatusRenderer,
    status,
    language = LANGUAGE.ENGLISH,
    messageGenerator,
    children,
  }) => {
    const side = getSide(status);
    const normalizedStatusCode = getNormalizedStatusCode(status);

    const { message, description, icon } =
      messageGenerator({
        language: language,
        normalizedStatusCode: normalizedStatusCode,
        statusCode: status.sc,
        statusMessage: status.stn,
      }) || {};

    const activeTeam =
      side === SIDE.NO_SIDE
        ? undefined
        : side === SIDE.HOME
        ? status.T1n
        : status.T2n;

    const [{ width, height }, setState] = useState({ width: 0, height: 0 });
    const overlayRef = useRef<HTMLDivElement>(null);
    const messageContainerRef = useRef<HTMLDivElement>(null);

    useLayoutEffect(() => {
      setState((state) => ({
        ...state,
        height: overlayRef.current ? overlayRef.current.clientHeight : 0,
        width: overlayRef.current ? overlayRef.current.clientWidth : 0,
      }));
      const ro = new ResizeObserver((entries: any, observer: any) => {
        for (var { contentRect } of entries) {
          const { width, height } = contentRect;
          setState((state) => ({
            ...state,
            width,
            height,
          }));
        }
      });

      overlayRef.current && ro.observe(overlayRef.current);

      return () => {
        ro.disconnect();
      };
    }, []);
    return (
      <OverlayContainer ref={overlayRef} className="overlay-container">
        <MessageContainer
          overlayWidth={width}
          overlayHeight={height}
          ref={messageContainerRef}
          className={`status-message-container sc-${status.sc} nsc-${normalizedStatusCode} side-${side} ${className}`.trim()}
          data-side={side}
        >
          <InnerContainer className="inner-container">
            {StatusRenderer && (
              <StatusRenderer
                side={side}
                homeTeam={status.T1n}
                awayTeam={status.T2n}
                activeTeam={activeTeam}
                code={status.sc}
                message={message}
                normalizedCode={normalizedStatusCode}
                status={status}
                language={language}
                description={description}
                Icon={icon}
                width={width}
                height={height}
              />
            )}
          </InnerContainer>
          {children}
        </MessageContainer>
      </OverlayContainer>
    );
  }
);

type MessageContainerProps = {
  overlayWidth: number;
  overlayHeight: number;
};

const MessageContainer = styled.div<MessageContainerProps>`
  display: flex;
  width: 100%;
  height: 100%;
`;

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;
