import { FC, memo, useMemo } from "react";
import { MatchStatus } from "service";
import styled from "styled-components";
import { SIDE } from "_constants";

export const ThrowInIndicator: FC<{ status: MatchStatus }> = memo(
  ({ status: { x, y, sc, nsc, side } }) => {
    const throwInAngle = useMemo(() => {
      if (!side || !y) return 0;

      if (y < 0.5) {
        return 40 * (side === SIDE.HOME ? -1 : 1);
      } else {
        return -180 + 40 * (side === SIDE.HOME ? 1 : -1);
      }
    }, [side, y]);

    if (!x || !y) return <></>;

    return (
      <>
        <PathContainer>
          <svg width="400px" height="180px" viewBox="0 0 400 180">
            {nsc === "1024" && (
              <ThrowInGroup x={x} y={y} data-side={side} angle={throwInAngle}>
                <path
                  opacity="0.1"
                  d="m 0 0, -16.11111111111111 20.299999999999997 c 0,0,16.11111111111111,8.055555555555555, 32.22222222222222 0"
                ></path>
                <path
                  opacity="0.08"
                  d="m 0 0, -32.77777777777778 41.3 c 0,0,32.77777777777778,16.38888888888889, 65.55555555555556 0"
                ></path>
                <path opacity="0.06"></path>
              </ThrowInGroup>
            )}
          </svg>
        </PathContainer>
      </>
    );
  }
);

const PathContainer = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  left: 0;

  & > svg {
    width: 100%;
    height: 100%;
  }
`;

const ThrowInGroup = styled.g<{ angle?: number; x: number; y: number }>`
  transform: translate(
      ${(props) => props.x * 400}px,
      ${(props) => props.y * 180}px
    )
    rotate(${(props) => props.angle}deg) scale(0.8);
`;
