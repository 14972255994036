import { ComponentType } from "react";
import { LANGUAGE } from "_constants";
import { ReactComponent as PenaltyIcon } from "./assets/penalty.svg";
import { ReactComponent as ShotIcon } from "./assets/staff.svg";

const MESSAGES_EN: Record<string, string> = {
  "1270": "On Possession",
  "1271": "Penalty",
  "1272": "Power Play Over",
  "1273": "Shot",
  "1274": "Goal",
  "1275": "Penalty Shot",
  "1276": "Penalty Shot Missed",
  "1277": "Pulled Keeper",
  "1278": "Keeper Back In Goal",
  "1280": "End of 2nd Period",
  "1281": "Overtime Start",
  "1282": "Overtime End",
  "1283": "Penalty Shootout",
  "1284": "Face Off",
  "1285": "Puck Dropped",
  "1286": "Face Off Winner",
  "1287": "Timeout",
  "1288": "Icing",
  "1289": "Power Play",
  "1295": "In Possession",
};

const MESSAGES_ALB: Record<string, string> = {
  "1270": "On Possession",
  "1271": "Penalty",
  "1272": "Power Play Over",
  "1273": "Shot",
  "1274": "Goal",
  "1275": "Penalty Shot",
  "1276": "Penalty Shot Missed",
  "1277": "Pulled Keeper",
  "1278": "Keeper Back In Goal",
  "1280": "End of 2nd Period",
  "1281": "Overtime Start",
  "1282": "Overtime End",
  "1283": "Penalty Shootout",
  "1284": "Face Off",
  "1285": "Puck Dropped",
  "1286": "Face Off Winner",
  "1287": "Timeout",
  "1288": "Icing",
  "1289": "Power Play",
  "1295": "In Possession",
};

export const MESSAGES = {
  [LANGUAGE.ENGLISH]: MESSAGES_EN,
  [LANGUAGE.ALBANIAN]: MESSAGES_ALB,
};

export const SECONDARY_DICT: Record<number, Record<string, string>> = {
  [LANGUAGE.ALBANIAN]: {
    "End of 1st Period": "ALB:End of 1st Period",
    "End of 2nd Period": "ALB:End of 2nd Period",
  },
};

export const ICONS: Record<string, ComponentType<any>> = {
  "1271": PenaltyIcon,
  "1273": ShotIcon,
};
