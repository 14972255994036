import { MatchStatus } from "service";
import { SIDE } from "_constants";
import { FREE_KICK_EVENTS } from "./indicators/FreeKickIndicator";

const createPositionSetter = (status: MatchStatus) => (
  x?: number,
  y?: number
) => {
  if (x) status.x = x;
  if (y) status.y = y;
};

const addMissingPosition = (status: MatchStatus) => {
  const setPosition = createPositionSetter(status);
  if (!status.y || !status.x) {
    //DANGEROUS ATTACK
    if (status.nsc === "1000" && status.side === SIDE.HOME)
      setPosition(0.8, 0.5);

    if (status.nsc === "1000" && status.side === SIDE.AWAY)
      setPosition(0.2, 0.5);

    //ATTACK
    if (status.nsc === "1001" && status.side === SIDE.HOME)
      setPosition(0.55, 0.5);
    if (status.nsc === "1001" && status.side === SIDE.AWAY)
      setPosition(0.45, 0.5);

    //CORNER
    if (
      (status.nsc === "1901" || status.nsc === "1004") &&
      status.side === SIDE.HOME
    )
      setPosition(0.99, 0.01);

    if (status.nsc === "1902" && status.side === SIDE.HOME)
      setPosition(0.99, 0.99);

    if (
      (status.nsc === "1901" || status.nsc === "1004") &&
      status.side === SIDE.AWAY
    )
      setPosition(0.01, 0.01);

    if (status.nsc === "1902" && status.side === SIDE.AWAY)
      setPosition(0.01, 0.99);

    //THROW
    if (status.nsc === "1024" && status.side === SIDE.HOME)
      setPosition(0.7, 0.01);

    if (status.nsc === "1024" && status.side === SIDE.AWAY)
      setPosition(0.4, 0.99);

    //FREEKICK
    if (
      status.nsc &&
      FREE_KICK_EVENTS.includes(status.nsc) &&
      status.side === SIDE.AWAY
    )
      setPosition(0.4, 0.7);

    if (
      status.nsc &&
      FREE_KICK_EVENTS.includes(status.nsc) &&
      status.side === SIDE.HOME
    )
      setPosition(0.6, 0.3);
  }
};

export const adjustBallPosition = (status: MatchStatus) => {
  const setPosition = createPositionSetter(status);

  addMissingPosition(status);

  //GOALKICK
  if (status.nsc === "1007" && status.side === SIDE.HOME)
    setPosition(0.045, 0.6);

  if (status.nsc === "1007" && status.side === SIDE.AWAY)
    setPosition(0.955, 0.6);

  //PENALTY
  if (
    (status.nsc === "1008" || status.nsc === "0021") &&
    status.side === SIDE.HOME
  )
    setPosition(0.08, 0.5);

  if (
    (status.nsc === "1008" || status.nsc === "0021") &&
    status.side === SIDE.AWAY
  )
    setPosition(0.92, 0.5);

  //THROW
  if (status.nsc === "1024")
    setPosition(undefined, status.y && status.y < 0.5 ? 0.01 : 0.99);

  //CORNER
  if (status.nsc && ["1901", "1004", "1902"].includes(status.nsc))
    setPosition(
      status.x && status.x < 0.5 ? 0.01 : 0.99,
      status.y && status.y < 0.5 ? 0.01 : 0.99
    );
};
