import { FC, useMemo } from "react";
import styled from "styled-components";
import { OverlayContainer } from "components/OverlayContainer";
import { SIDE } from "_constants";

type CurrentStreakProps = {
  statusText: string;
  team: string;
  side: SIDE;
};

export const CurrentStreak: FC<CurrentStreakProps> = ({
  statusText,
  side,
  team,
  children,
}) => {
  const streak = useMemo(() => {
    const parts = statusText.split("|");
    return parts.find((part) => part.startsWith("INFO="))?.substring(5);
  }, [statusText]);

  return (
    <OverlayContainer>
      <MainContainer>
        <Container isAway={side === SIDE.AWAY}>
          <Team>{team}</Team>
          <Streak>
            {children} {streak}
          </Streak>
        </Container>
      </MainContainer>
    </OverlayContainer>
  );
};

const MainContainer = styled.div<{ isAway?: boolean }>`
  position: absolute;
  width: 50%;
  height: 100%;
  top: 0;
  left: ${(props) => (props.isAway ? "50%" : "0")};
  display: flex;
`;

const Container = styled.div<{ isAway?: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  border-width: 0.7vw;
  border-color: ${(props) => (props.isAway ? "black" : "white")};
  border-left-style: ${(props) => (props.isAway ? "solid" : "unset")};
  border-right-style: ${(props) => (props.isAway ? "unset" : "solid")};
  padding: 0 1.5vw;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: ${(props) => (!props.isAway ? "auto" : "3vw")};
  margin-right: ${(props) => (!props.isAway ? "3vw" : "unset")};
`;

const Team = styled.span`
  font-size: 3vw;
  font-weight: bold;
`;

const Streak = styled(Team)`
  color: #f6cfc6;
`;
