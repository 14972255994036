import {
  ComponentType,
  FC,
  memo,
  useCallback,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { MatchStatus } from "service";
import styled from "styled-components";
import { SIDE } from "_constants";
import { OverlayContainer } from "../../components/OverlayContainer";
import ResizeObserver from "resize-observer-polyfill";

type StatusMessageProps = {
  className?: string;
  team?: string;
  side: SIDE;
  Icon?: ComponentType<any>;
  title?: string;
  description?: string;
  normalizedCode: string;
  status: MatchStatus;
  width: number;
  height: number;
};

export const StatusMessage: FC<StatusMessageProps> = memo(
  ({
    team,
    side: _side,
    Icon,
    title,
    description,
    className,
    normalizedCode,
    status: { x: _x = 0.5, y: _y = 0.5, nsc, stn },
    width: overlayWidth,
    height: overlayHeight,
  }) => {
    let x = _x || 0.5;
    let y = _y || 0.5;

    if (x === 0 && y === 0) {
      x = 0.5;
      y = 0.5;
    }
    const side = _side === SIDE.AWAY ? "away" : "home";
    const [{ messageHeight, messageWidth }, setState] = useState({
      messageWidth: 0,
      messageHeight: 0,
    });
    const messageContainerRef = useRef<HTMLDivElement>(null);

    const teamName = stn?.includes("|") ? stn.split("|")[1] : team;

    useLayoutEffect(() => {
      const ro = new ResizeObserver((entries: any, observer: any) => {
        for (var { contentRect } of entries) {
          const { width, height } = contentRect;

          setState((state) => ({
            ...state,
            messageWidth: width,
            messageHeight: height,
          }));
        }
      });

      messageContainerRef.current && ro.observe(messageContainerRef.current);

      return () => {
        ro.disconnect();
      };
    }, []);

    const placeMessage = useCallback(
      (_x: number, _y: number) => {
        let x = _x - messageWidth / 2;
        let y = _y - messageHeight / 2;

        const xLeft = x - messageWidth / 2 - overlayWidth / 15;
        const xRight = x + messageWidth / 2 + overlayWidth / 15;

        x = side === "home" ? xLeft : xRight;

        if (x < 0 || x + messageWidth > overlayWidth)
          x = side === "home" ? xRight : xLeft;

        y += (y < overlayHeight / 2 ? 1 : -1) * messageHeight * 1.5;

        return [x, y];
      },
      [side, messageWidth, messageHeight, overlayWidth, overlayHeight]
    );

    if (nsc === "1003") return <></>;

    if (!teamName)
      return (
        <OverlayContainer>
          <CenteredStatus>
            <div style={{ display: "flex" }}>
              {Icon && (
                <IconWrapper className="icon-container">
                  <Icon />
                </IconWrapper>
              )}
              <span style={{ margin: "auto 0" }}>{title}</span>
            </div>
          </CenteredStatus>
        </OverlayContainer>
      );

    const ballPosition = {
      x: (x || 0.48) * overlayWidth,
      y: (y || 0.48) * overlayHeight,
    };

    let [messageX, messageY] = placeMessage(ballPosition.x, ballPosition.y);

    if (messageX === 0 && messageY === 0) {
      messageY = overlayHeight / 2;
      messageX = overlayWidth / 2;
    }

    return (
      <OverlayContainer
        style={
          {
            "--x": messageX + "px",
            "--y": messageY + "px",
          } as any
        }
      >
        <MessageContainer
          className={`status-message-container nsc-${normalizedCode} ${className}`}
          data-side={side}
        >
          <div
            className="d-flex flex-column inner-container my-auto"
            ref={messageContainerRef}
          >
            <TeamName className="team-name">{teamName}</TeamName>
            <Status className="status-name">{title}</Status>
            {description && (
              <StatusDescription className="status-description">
                {description}
              </StatusDescription>
            )}
          </div>
          {Icon && (
            <IconWrapper className="icon-container">
              <Icon />
            </IconWrapper>
          )}
        </MessageContainer>
      </OverlayContainer>
    );
  }
);

const HOME_COLOR = "red";
const AWAY_COLOR = "blue";

const MessageContainer = styled.div`
  display: flex;
  z-index: 999;

  transform: translate(var(--x), var(--y));
  transition: transform 300ms ease;
  margin-right: auto;
  margin-bottom: auto;

  &[data-side="home"] {
    .inner-container {
      border-right: 0.5vw solid ${HOME_COLOR};
      padding-right: 1vw;
    }
  }

  &[data-side="away"] {
    .inner-container {
      border-left: 0.5vw solid ${AWAY_COLOR};
      padding-left: 1vw;
    }
  }
`;
const TeamName = styled.span`
  font-size: 2.5vw;
  font-weight: bold;
  color: #addc7b;
`;
const Status = styled.span`
  font-size: 2.5vw;
  font-weight: bold;
  color: white;
`;

const StatusDescription = styled.span`
  font-size: 4vw;
  color: white;
`;

const IconWrapper = styled.div`
  margin: auto;
  padding: 0 1vw;

  svg {
    height: 6vw !important;
    width: auto !important;
  }
`;

const CenteredStatus = styled.span`
  color: white;
  font-size: 3.5vw;
  margin: auto;
  z-index: 9999;
  font-weight: bold;
`;
