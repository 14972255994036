import { FC, memo } from "react";
import { MatchStatus } from "service";
import styled from "styled-components";
import { SIDE } from "_constants";

const CORNER_EVENTS: string[] = ["1901", "1902", "1004"];

export const CornerIndicator: FC<{ status: MatchStatus }> = memo(
  ({ status: { nsc, side } }) => {
    if (!nsc || !CORNER_EVENTS.includes(nsc)) return <></>;

    return (
      <PathContainer>
        <svg width="400px" height="180px" viewBox="0 0 400 180">
          <CornerGroup isBottom={nsc === "1902"} isAway={side === SIDE.AWAY}>
            <path
              opacity="0.1"
              d="m 0 0, -8.055555555555555 31.900000000000002 c 0,0,8.055555555555555,4.027777777777778, 16.11111111111111 0"
            ></path>
            <path
              opacity="0.08"
              d="m 0 0, -16.38888888888889 64.9 c 0,0,16.38888888888889,8.194444444444445, 32.77777777777778 0"
            ></path>
            <path
              opacity="0.06"
              d="m 0 0, -27.5 108.9 c 0,0,27.5,13.75, 55 0"
            ></path>
          </CornerGroup>
        </svg>
      </PathContainer>
    );
  }
);

const PathContainer = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  left: 0;

  & > svg {
    width: 100%;
    height: 100%;
  }
`;

const CornerGroup = styled.g<{ isBottom?: boolean; isAway?: boolean }>`
  transform: translateX(${(props) => (props.isAway ? 2 : 398)}px)
    translateY(${(props) => (props.isBottom ? 180 : 2)}px)
    rotate(
      ${(props) => {
        if (props.isBottom !== true) {
          return props.isAway ? -14 : 14;
        } else {
          return props.isAway ? 194 : 166;
        }
      }}deg
    )
    scale(0.6);
`;
