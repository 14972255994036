import React, { FC } from "react";
import styled from "styled-components";
import { StatusRendererProps } from "../../components/Overlay";
import { SIDE } from "_constants";
import { Breakdown } from "./status-renderers/Breakdown";
import { CurrentStreak } from "./status-renderers/CurrentStreak";
import { EndOfSet } from "./status-renderers/EndOfSet";
import { LastSixPoints } from "./status-renderers/LastSixPoints";
import { Rally } from "./status-renderers/Rally";
import { Score } from "./status-renderers/Score";
import { ServiceAnimation } from "./status-renderers/ServiceAnimation";
import { TeamName } from "./status-renderers/TeamName";
import { Timeout } from "./status-renderers/Timeout";
import { MESSAGES } from "sports/Basketball/constants";

export const VolleyballStatusRenderer: FC<StatusRendererProps> = ({
  message,
  side,
  homeTeam,
  awayTeam,
  normalizedCode,
  activeTeam,
  status,
  language,
}) => {
  return (
    <>
      <Container>
        {normalizedCode === "1248" && <StyledServiceAnimation side={side} />}

        <TeamNameContainer>
          <TeamName
            name={homeTeam}
            isAway={false}
            served={side === SIDE.HOME && normalizedCode === "1249"}
            servedText={MESSAGES[language]["9999"]}
          />
          <TeamName
            name={awayTeam}
            isAway={true}
            served={side === SIDE.AWAY && normalizedCode === "1249"}
            servedText={MESSAGES[language]["9999"]}
          />
        </TeamNameContainer>
      </Container>

      {normalizedCode === "1257" && <LastSixPoints statusText={status.sts} />}
      {normalizedCode === "1259" && activeTeam && (
        <CurrentStreak team={activeTeam} statusText={status.sts} side={side}>
          {message}
        </CurrentStreak>
      )}
      {normalizedCode === "1258" && (
        <Breakdown statusText={status.sts}>{message}</Breakdown>
      )}
      {normalizedCode === "1250" && <Score statusText={status.sts} />}
      {normalizedCode === "1249" && <Rally>{message}</Rally>}
      {(normalizedCode === "1255" || normalizedCode === "1256") && (
        <Timeout>{message}</Timeout>
      )}
      {normalizedCode === "1254" && <EndOfSet>{message}</EndOfSet>}
    </>
  );
};

const TeamNameContainer = styled.div`
  display: flex;
  margin-top: auto;
  margin-bottom: 4vw;
  justify-content: space-between;
`;

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
`;

const StyledServiceAnimation = styled(ServiceAnimation)`
  position: absolute;
  top: 25vw;
  left: 0;
  right: 0;
`;

// const SERVICE_CODES = ["1248", "1249", "1250", "1254", "1260"];
