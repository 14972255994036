import { memo, useMemo } from 'react';
import React from 'react';
import qs from 'qs';
import { LANGUAGE } from '_constants';
import { MatchSelector } from 'containers/MatchSelector';
import { DebugMode } from 'containers/DebugMode';
import { Simulator } from 'containers/Simulator';

export const App = memo(() => {
  const [eventId, lang] = useMemo(() => {
    const [, eventId] = window.location.pathname.split('/') as [undefined, string];
    let { lang: langString } = qs.parse(window.location.search.slice(1));
    const lang = Number(langString) || 0;

    return [eventId, lang as LANGUAGE];
  }, []);

  const isDebugPage = window.location.pathname === '/debug';

  if (!isDebugPage && !eventId) return <MatchSelector />;

  return (
    <>
      <>{isDebugPage ? <DebugMode /> : <Simulator eventId={eventId} lang={lang} />}</>
    </>
  );
});
