import { FC } from "react";
import styled from "styled-components";
import { Row } from ".";
import { AWAY_COLOR, HOME_COLOR } from "../constants";
import { LineChart } from "./LineChart";

export const CardsAndCorners: FC<{
  corners?: [number, number];
  yellowCards?: [number, number];
  redCards?: [number, number];
  onTarget?: [number, number];
  offTarget?: [number, number];
}> = ({
  corners = [0, 0],
  yellowCards = [0, 0],
  redCards = [0, 0],
  onTarget = [0, 0],
  offTarget = [0, 0],
}) => {
  return (
    <Column style={{ marginTop: "3vw", marginBottom: "2vw" }}>
      <Row style={{ marginBottom: "-1.5vw" }}>
        <Value style={{ marginBottom: "0.2vw" }}>On Target</Value>
      </Row>
      <Row style={{ marginBottom: "0vw" }}>
        <CardContainer>
          <Flag />
        </CardContainer>
        <CardContainer>
          <RedCard />
        </CardContainer>
        <CardContainer>
          <YellowCard />
        </CardContainer>
        <GraphContainer>
          <Row>
            <Value style={{ marginRight: "2vw" }}>{onTarget[0]}</Value>
            <LineChart homeValue={onTarget[0]} awayValue={onTarget[1]} />
            <Value style={{ marginLeft: "2vw" }}>{onTarget[1]}</Value>
          </Row>
        </GraphContainer>
        <CardContainer>
          <YellowCard />
        </CardContainer>
        <CardContainer>
          <RedCard />
        </CardContainer>
        <CardContainer>
          <Flag isAway />
        </CardContainer>
      </Row>

      <Row style={{ marginBottom: "-2vw" }}>
        <Value>Off Target</Value>
      </Row>
      <Row>
        <CardContainer>
          <Value>{corners[0]}</Value>
        </CardContainer>
        <CardContainer>
          <Value>{redCards[0]}</Value>
        </CardContainer>
        <CardContainer>
          <Value>{yellowCards[0]}</Value>
        </CardContainer>
        <GraphContainer>
          <Row>
            <Value style={{ marginRight: "2vw" }}>{offTarget[0]}</Value>
            <LineChart homeValue={offTarget[0]} awayValue={offTarget[1]} />
            <Value style={{ marginLeft: "2vw" }}>{offTarget[1]}</Value>
          </Row>
        </GraphContainer>
        <CardContainer>
          <Value>{yellowCards[1]}</Value>
        </CardContainer>
        <CardContainer>
          <Value>{redCards[1]}</Value>
        </CardContainer>
        <CardContainer>
          <Value>{corners[1]}</Value>
        </CardContainer>
      </Row>
    </Column>
  );
};

const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  & > *:last-child {
    margin-top: 1vw;
  }
`;

const RedCard = styled.div`
  width: 2vw;
  height: 3vw;
  background: red;
  border-radius: 10%;
  margin: auto;
`;

const YellowCard = styled(RedCard)`
  background: yellow;
`;

const Value = styled.span`
  margin: auto;
  font-size: 3vw;
  color: gray;
`;

const CardContainer = styled.div`
  width: 4vw;
  margin: auto 0;
  display: flex;
`;

const GraphContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 40%;
  margin: auto 0;
  position: relative;
`;

const Flag = styled.div<{ isAway?: boolean }>`
  width: 3vw;
  height: 4vw;
  position: relative;
  margin: auto;

  &:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    background: white;
    height: 100%;
    width: 5%;
  }

  &:after {
    content: "";
    position: absolute;
    left: 5%;
    top: 0;
    background: ${(props) => (props.isAway ? AWAY_COLOR : HOME_COLOR)};
    height: 50%;
    width: 95%;
  }

  ${(props) => (props.isAway ? "transform: scaleX(-1);" : "")}
`;
