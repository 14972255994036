import { FC, memo, useEffect, useRef, useState } from "react";
import { MatchStatus } from "service";
import styled from "styled-components";
import { SIDE } from "_constants";
import { AWAY_COLOR, HOME_COLOR } from "./constants";

const PATH_LINE_MAX_COUNT = 5;

const NO_BALL_EVENTS: string[] = [
  "1005",
  "1006",
  "1003",
  "1030",
  "1031",
  "1032",
  "1033",
  "1034",
  "1035",
  "1036",
  "1011",
  "1012",
];
const PATH_STOPPER_EVENTS: string[] = [
  ...NO_BALL_EVENTS,
  "1010",
  "1009",
  "1024",
  "1007",
  "1234",
  "1003",
];

export const Ball: FC<{ status: MatchStatus }> = memo(
  ({ status: { x, y, sc, nsc, side, hidePath } }) => {
    const [{ path }, setState] = useState<{
      path: { x: number; y: number }[];
    }>({
      path: [],
    });

    const pXRef = useRef<number | undefined>(undefined);
    const pYRef = useRef<number | undefined>(undefined);

    useEffect(() => {
      if (!x || !y) {
        setState((state) => ({ ...state, path: [] }));
        return;
      }

      let addToPath = true;
      if (x === pXRef.current && y === pYRef.current) addToPath = false;
      pXRef.current = x;
      pYRef.current = y;

      setState((state) => ({
        ...state,
        path: addToPath ? [...state.path, { x, y }] : state.path,
      }));
    }, [x, y, sc]);

    const sideRef = useRef<SIDE | undefined>(SIDE.NO_SIDE);

    useEffect(() => {
      if (sideRef.current !== side) {
        setState((state) => ({
          ...state,
          path: state.path.length > 0 ? state.path.slice(-1) : [],
        }));
        sideRef.current = side;
      }
    }, [side]);

    useEffect(() => {
      if (nsc && PATH_STOPPER_EVENTS.includes(nsc))
        setState((state) => ({ ...state, path: [] }));
    }, [nsc]);

    if (!x || !y) return <></>;
    if (nsc && NO_BALL_EVENTS.includes(nsc)) return <></>;

    const color = side === SIDE.HOME ? HOME_COLOR : AWAY_COLOR;

    return (
      <>
        <PathContainer>
          <svg width="400px" height="180px" viewBox="0 0 400 180">
            <polyline
              points={path
                .slice(-PATH_LINE_MAX_COUNT - 1)
                .map(({ x, y }) => `${x * 400},${y * 180}`)
                .join(" ")}
              stroke={color}
              strokeWidth="1px"
              fill="none"
            />

            <circle
              cx={x * 400}
              cy={y * 180}
              r="0.3vw"
              fill="none"
              strokeWidth="0.05vw"
              stroke={color}
            >
              <animate
                attributeName="r"
                begin="0s"
                dur="1s"
                repeatCount="indefinite"
                from="0.3vw"
                to="1vw"
              />
              <animate
                attributeName="opacity"
                begin="0s"
                dur="1s"
                repeatCount="indefinite"
                from="1"
                to="0.3"
              />
            </circle>

            {path.slice(-PATH_LINE_MAX_COUNT).map((p, i, array) => (
              <circle
                r="3px"
                cx={p.x * 400}
                cy={p.y * 180}
                key={i}
                fill={color}
                opacity={
                  (array.length === PATH_LINE_MAX_COUNT && array.length - 1) ===
                  i
                    ? "0.2"
                    : "1"
                }
              />
            ))}
            {path.length >= PATH_LINE_MAX_COUNT + 1 && (
              <circle
                r="3px"
                cx={path[path.length - PATH_LINE_MAX_COUNT - 1].x * 400}
                cy={path[path.length - PATH_LINE_MAX_COUNT - 1].y * 180}
                fill={color}
                opacity="0.2"
                style={{ opacity: 0.3 }}
              />
            )}

            <circle
              cx="0"
              cy="0"
              r="4px"
              fill="white"
              strokeWidth="0.1vw"
              stroke={color}
              transform={`translate(${x * 400},${y * 180})`}
              z="1000"
              style={{ transition: "transform 500ms ease" }}
            ></circle>
          </svg>
        </PathContainer>
      </>
    );
  }
);

const PathContainer = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  left: 0;

  & > svg {
    width: 100%;
    height: 100%;
  }
`;
