import { FC, useMemo } from "react";
import styled from "styled-components";
import { OverlayContainer } from "components/OverlayContainer";

export const Breakdown: FC<{ statusText: string }> = ({
  statusText,
  children,
}) => {
  const pointsWon = useMemo(() => {
    return statusText
      .split("|")
      .find((part) => part.startsWith("Points Won on Serve"))
      ?.split("=")[1]
      ?.split(":");
  }, [statusText]);

  if (!pointsWon || !pointsWon[0] || !pointsWon[1]) return <></>;

  return (
    <OverlayContainer>
      <Container>
        <Title>{children}</Title>
        <PercentageContainer>
          <Home>
            <Number>{pointsWon[0]}</Number>
            <Percentage>%</Percentage>
          </Home>
          <Away>
            <Number>{pointsWon[1]}</Number>
            <Percentage>%</Percentage>
          </Away>
        </PercentageContainer>
      </Container>
    </OverlayContainer>
  );
};

const Container = styled.div`
  position: relative;
  display: flex;
  margin: auto;
  flex-direction: column;
  height: 100%;
  padding-top: 5vw;
`;

const Home = styled.div`
  padding-bottom: 0.4vw;
  border-bottom: 0.7vw solid white;
  margin: 0 3.5vw;
`;
const Away = styled(Home)`
  border-bottom: 0.7vw solid black;
`;

const Number = styled.span`
  font-size: 5vw;
  color: #fce04e;
  font-weight: bold;
`;

const Percentage = styled(Number)`
  font-size: 3.5vw;
  padding-left: 0.4vw;
`;

const PercentageContainer = styled.div`
  display: flex;
  margin-top: 10vw;
`;

const Title = styled.span`
  background: #f8d5ce;
  color: #aa7163;
  margin-left: auto;
  margin-right: auto;
  font-size: 2vw;
  padding: 0.7vw;
  border-radius: 0.4vw;
`;
