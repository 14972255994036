import {
  ComponentType,
  FC,
  memo,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import styled from "styled-components";
import { SIDE } from "_constants";
import { OverlayContainer } from "../../components/OverlayContainer";

type StatusMessageProps = {
  className?: string;
  team?: string;
  side: SIDE;
  Icon?: ComponentType<any>;
  title?: string;
  description?: string;
  normalizedCode: string;
};

export const StatusMessage: FC<StatusMessageProps> = memo(
  ({
    team: teamName,
    side: _side,
    Icon,
    title,
    description,
    className,
    normalizedCode,
  }) => {
    const side = _side === SIDE.AWAY ? "away" : "home";
    const [{ width, height }, setState] = useState({ width: 0, height: 0 });
    const overlayRef = useRef<HTMLDivElement>(null);
    const messageContainerRef = useRef<HTMLDivElement>(null);

    useLayoutEffect(() => {
      const listener = () => {
        setState((state) => ({
          ...state,
          width: overlayRef.current?.clientWidth || 0,
          height: overlayRef.current?.clientHeight || 0,
        }));
      };
      window.addEventListener("resize", listener);
      listener();
      return () => {
        window.removeEventListener("resize", listener);
      };
    }, []);

    if (!teamName)
      return (
        <OverlayContainer>
          <CenteredStatus>{title}</CenteredStatus>
        </OverlayContainer>
      );

    return (
      <OverlayContainer ref={overlayRef} className="overlay-container">
        <MessageContainer
          overlayWidth={width}
          overlayHeight={height}
          ref={messageContainerRef}
          className={`status-message-container nsc-${normalizedCode} ${className}`}
          data-side={side}
        >
          <div className="d-flex flex-column inner-container my-auto">
            <TeamName className="team-name">{teamName}</TeamName>
            <Status className="status-name">{title}</Status>
            {description && (
              <StatusDescription className="status-description">
                {description}
              </StatusDescription>
            )}
          </div>
          {Icon && (
            <IconWrapper className="icon-container">
              <Icon />
            </IconWrapper>
          )}
        </MessageContainer>
      </OverlayContainer>
    );
  }
);

const HOME_COLOR = "#1748a3";
const AWAY_COLOR = "#f4be44";

type MessageContainerProps = {
  overlayWidth: number;
  overlayHeight: number;
};

const MessageContainer = styled.div<MessageContainerProps>`
  display: flex;
  margin: auto;

  &[data-side="home"] {
    .inner-container {
      border-right: 0.5vw solid ${HOME_COLOR};
      padding-right: 1vw;
    }

    flex-direction: row;
  }

  &[data-side="away"] {
    .inner-container {
      border-left: 0.5vw solid ${AWAY_COLOR};
      padding-left: 1vw;
    }
    flex-direction: row-reverse;
  }

  &[data-side="home"] > * {
    margin-left: auto;
  }

  &[data-side="away"] > * {
    margin-right: auto;
  }
`;
const TeamName = styled.span`
  font-size: 2.5vw;
  font-weight: bold;
  color: #4c4231;
`;
const Status = styled.span`
  font-size: 2.5vw;
  font-weight: bold;
  color: #537ba3;
`;

const StatusDescription = styled.span`
  font-size: 4vw;
  color: white;
`;

const IconWrapper = styled.div`
  margin: auto;
  padding: 0 1vw;

  svg {
    height: 6vw !important;
    width: auto !important;
  }
`;

const CenteredStatus = styled.span`
  color: #537ba3;
  font-size: 3.5vw;
  margin: auto;
  z-index: 9999;
  font-weight: bold;
`;
