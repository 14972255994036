import { FC, useEffect, useState } from "react";
import { MatchStatus } from "service";
import styled from "styled-components";

export const LivePuck: FC<{ status: MatchStatus }> = ({ status: { xy } }) => {
  const [{ x, y, isActive }, setState] = useState({
    x: 0,
    y: 0,
    isActive: false,
  });

  useEffect(() => {
    if (!xy) {
      setState((state) => ({ ...state, isActive: false }));
      return;
    }
    const [x, y] = xy.split(",");
    setState({ x: Number(x), y: Number(y), isActive: true });
  }, [xy]);

  if (!isActive) return <></>;

  return <Puck x={x} y={y} />;
};

const HEIGHT = "2vw";
const Puck = styled.div<{ x: number; y: number }>`
  background: black;
  border-radius: 100%;
  width: ${HEIGHT};
  height: ${HEIGHT};
  position: absolute;
  left: ${(props) => props.x * 100}%;
  top: ${(props) => props.y * 100}%;
  transition-property: left top;
  transition-duration: 2s;
  transition-timing-function: cubic-bezier(0.55, 0, 0.11, 1);
  z-index: 1;
`;
