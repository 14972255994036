import React, { FC, useState } from "react";
import { MatchStatus } from "service";
import styled from "styled-components";
import { SimulatorInner } from "./Simulator";

export const DebugMode: FC = () => {
  const [status, setStatus] = useState<MatchStatus | undefined>();

  const [statusList, setStatusList] = useState<MatchStatus[]>([]);

  return (
    <>
      <textarea
        style={{ width: "100%", height: "100px" }}
        onChange={(e) => {
          try {
            const st = JSON.parse(e.target.value);
            if (Array.isArray(st)) {
              setStatusList(st);
              setStatus(st[0]);
            } else {
              setStatus(st);
            }
          } catch (e) {}
        }}
      />
      <ButtonContainer>
        {statusList.map((status, i) => (
          <button key={i} onClick={(e) => setStatus(status)}>
            {i}
          </button>
        ))}
      </ButtonContainer>
      {status && <SimulatorInner status={status} />}
    </>
  );
};

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;
