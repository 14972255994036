import { FC, memo } from "react";
import { MatchStatus } from "service";
import styled from "styled-components";
import { SIDE } from "_constants";

const GOAL_KICK_EVENTS: string[] = ["1007"];

export const GoalKickIndicator: FC<{ status: MatchStatus }> = memo(
  ({ status: { nsc, side, x, y } }) => {
    if (!nsc || !GOAL_KICK_EVENTS.includes(nsc) || !x || !y) return <></>;
    return (
      <PathContainer>
        <svg width="400px" height="180px" viewBox="0 0 400 180">
          <GoalKickGroup isAway={side === SIDE.AWAY} x={x} y={y}>
            <path
              opacity="0.1"
              d="m 0 0, -18.125 36.25 c 0,0,18.125,9.0625, 36.25 0"
            ></path>
            <path
              opacity="0.08"
              d="m 0 0, -36.875 73.75 c 0,0,36.875,18.4375, 73.75 0"
            ></path>
            <path
              opacity="0.06"
              d="m 0 0, -55.625 111.25 c 0,0,55.625,27.8125, 111.25 0"
            ></path>
          </GoalKickGroup>
        </svg>
      </PathContainer>
    );
  }
);

const PathContainer = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  left: 0;

  & > svg {
    width: 100%;
    height: 100%;
  }
`;

const GoalKickGroup = styled.g<{ isAway?: boolean; x: number; y: number }>`
  transform: translate(
      ${(props) => props.x * 400}px,
      ${(props) => props.y * 180}px
    )
    rotate(${(props) => (props.isAway ? 90 : -90)}deg) scale(0.5);
`;
