import React, { FC } from "react";
import { StatusMessage } from "./StatusMessage";
import { StatusRendererProps } from "../../components/Overlay";

export const VolleyballStatusRenderer: FC<StatusRendererProps> = ({
  message,
  side,
  homeTeam,
  awayTeam,
  normalizedCode,
  activeTeam,
  status,
  language,
  Icon,
  description,
}) => {
  return (
    <>
      {normalizedCode && (
        <StatusMessage
          title={message}
          normalizedCode={normalizedCode}
          team={activeTeam}
          side={side}
          Icon={Icon}
        />
      )}
    </>
  );
};
