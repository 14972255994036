import React, {
  FC,
  useMemo,
  useCallback,
  useState,
  useEffect,
  Suspense,
} from "react";
import {
  Basketball,
  Volleyball,
  Baseball,
  IceHockey,
  Football,
} from "../sports";
import * as service from "../service";
import { LANGUAGE } from "_constants";
import { CountdownTimerProvider } from "components";
import { getXY } from "utils/getXY";
import { getSide } from "utils/get-side";
import { getNormalizedStatusCode } from "utils/get-normalized-status-code";
import styled from "styled-components";

const COMPONENTS = {
  [service.SPORT.BASKETBALL]: Basketball,
  [service.SPORT.VOLLEYBALL]: Volleyball,
  [service.SPORT.BASEBALL]: Baseball,
  [service.SPORT.ICE_HOCKEY]: IceHockey,
  [service.SPORT.FOOTBALL]: Football,
};

const Debugger = React.lazy(() =>
  process.env.NODE_ENV === "development" ||
  process.env.REACT_APP_PREVIEW === "true"
    ? import("../components/Debugger")
    : import("../components/FakeDebugger")
);

export const Simulator: FC<{
  lang?: LANGUAGE;
  eventId: string;
}> = ({ lang, eventId }) => {
  const [status, setStatus] = useState<
    service.MatchStatus | undefined | null
  >();

  const fetchStatus = useCallback(async () => {
    let socketConnection: any;
    let listener: any;

    if (!eventId) return;
    try {
      const status = await service.getStatus(eventId);

      listener = ({ value }: { value: service.MatchStatus }) => {
        setStatus({
          ...value,
        });
      };
      socketConnection = service.getSocketConnection(eventId);
      socketConnection.on("POSITION", listener);

      setStatus({
        ...status,
      });
    } catch {
      setStatus(null);
    }
    return () => {
      socketConnection.off("POSITION", listener);
    };
  }, [eventId]);

  useEffect(() => {
    if (eventId) fetchStatus();
  }, [eventId, fetchStatus]);

  if (
    process.env.NODE_ENV === "development" ||
    process.env.REACT_APP_PREVIEW === "true"
  )
    if (status === null) return <>Not Found</>;
  if (!status) return <>Loading</>;

  return <SimulatorInner status={status} lang={lang} />;
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const SimulatorInner: FC<{
  status: service.MatchStatus;
  lang?: LANGUAGE;
}> = ({ status: _status, lang }) => {
  const [x, y] = getXY(_status);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const status = {
    ..._status,
    side: getSide(_status),
    nsc: getNormalizedStatusCode(_status),
    x,
    y,
  };
  
  const Component = useMemo(() => {
    if (!status) return undefined;
    return COMPONENTS[status.s];
  }, [status]);

  return (
    <>
      <Container>
        <CountdownTimerProvider startTime={status.tm}>
          {Component && <Component status={status} language={lang} />}
        </CountdownTimerProvider>
      </Container>
      <Suspense fallback={<></>}>
        <Debugger status={status} />
      </Suspense>
    </>
  );
};
