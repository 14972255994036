import { ComponentType } from "react";
import { LANGUAGE } from "_constants";
import { ReactComponent as YellowCard } from "./assets/yellow-card.svg";
import { ReactComponent as RedCard } from "./assets/red-card.svg";
import { ReactComponent as VarGoal } from "./assets/var-goal.svg";
import { ReactComponent as VarInProcess } from "./assets/var-in-process.svg";
import { ReactComponent as VarPenalty } from "./assets/var-penalty.svg";
import { ReactComponent as VarRedCard } from "./assets/var-red-card.svg";
import { ReactComponent as Substitution } from "./assets/substitution.svg";
import { ReactComponent as Penalty } from "./assets/penalty.svg";
import { ReactComponent as Injury } from "./assets/injury.svg";
import { ReactComponent as Offside } from "./assets/offside.svg";
import { ReactComponent as PenaltyGoal } from "./assets/penalty-goal.svg";
import { ReactComponent as PenaltyMissed } from "./assets/penalty-missed.svg";
import { ReactComponent as Halftime } from "./assets/halftime.svg";
import { ReactComponent as Fulltime } from "./assets/fulltime.svg";
import { ReactComponent as Goal } from "./assets/goal.svg";

export const ICONS: Record<string, ComponentType<any>> = {
  "1005": YellowCard,
  "1006": RedCard,
  "1332": VarGoal,
  "1331": VarRedCard,
  "1333": VarPenalty,
  "1330": VarInProcess,
  "1013": Substitution,
  "0008": PenaltyGoal,
  "0009": PenaltyMissed,
  "0021": Penalty,
  "1025": Injury,
  "1234": Offside,
  "1015": Halftime,
  "1017": Fulltime,
  "1003": Goal,
};

const MESSAGES_EN: Record<string, string> = {
  "1000": "Dangerous Attack",
  "1001": "Attack",
  "1002": "In Possession",
  "1003": "Goal",
  "1004": "Corner",
  "1005": "Yellow Card",
  "1006": "Red Card",
  "1007": "Goal kick",
  "1008": "Penalty",
  "1009": "Direct Free kick",
  "1010": "Simple Free kick",
  "1024": "Throw In",
  "0008": "Penalty Scored",
  "0009": "Penalty Missed",
  "0021": "Penalty",
  "1011": "Shot on goal",
  "1012": "Shot off goal",
  "1013": "Substitution",
  "1014": "Kick off",
  "1015": "Half Time",
  "1016": "Second Half",
  "1017": "Full Time",
  "1018": "Start Extra Time1",
  "1019": "Extra Time Half Time",
  "1020": "Start Extra Time2",
  "1021": "End Of Extra Time",
  "1022": "Penalty Shoot Out",
  "1023": "Penalty Missed",
  "1025": "Injury",
  "1026": "Injury Time",
  "1237": "Zoned Throw",
  "1233": "Match Info",
  "1234": "Offside",
  "1238": "Substitution",
  "1332": "Video Referee Reviewing Goal (VAR)",
  "1331": "Video Referee Reviewing RedCard (VAR)",
  "1333": "Video Referee Reviewing Penalty (VAR)",
  "1330": "Video Referee In Progress (VAR)",
  "1901": "Corner Top",
  "1902": "Corner Bottom",
  "1931": "Freekick",
  "1932": "Freekick",
  "1933": "Freekick",
  "1934": "Freekick",
  "1935": "Freekick",
  "1936": "Freekick",
};

const MESSAGES_ALB: Record<string, string> = {
  "1000": "Dangerous Attack",
  "1001": "Attack",
  "1002": "In possession",
  "1004": "Corner",
  "1007": "Goal kick",
  "1008": "Penalty",
  "1009": "Direct Free kick",
  "1010": "Simple Free kick",
  "1024": "Throw",
  "0008": "Penalty Score",
  "0009": "Penalty Miss",
  "0021": "Penalty To Take",
  "1003": "Goal",
  "1005": "Yellow Card",
  "1006": "Red Card",
  "1011": "Shot on Target",
  "1012": "Shot off Target",
  "1013": "Substitution",
  "1014": "Kick off",
  "1015": "Half time",
  "1016": "Second half",
  "1017": "Full time",
  "1018": "Start Extra Time1",
  "1019": "Extra Time Half Time",
  "1020": "Start Extra Time2",
  "1021": "End Of Extra Time",
  "1022": "Penalty shoot out",
  "1023": "Penalty missed",
  "1025": "Injury",
  "1026": "Injury Time",
  "1237": "Zoned Throw",
  "1233": "Match Info",
  "1234": "Offside",
  "1238": "Substitution",
  "1332": "VideoRefereeReviewingGoal (VAR)",
  "1331": "VideoRefereeReviewingRedCard (VAR)",
  "1333": "VideoRefereeReviewingPenalty (VAR)",
  "1330": "VideoRefereeInProgress (VAR)",
  "1901": "Corner",
  "1902": "Corner",
  "1931": "Freekick",
  "1932": "Freekick",
  "1933": "Freekick",
  "1934": "Freekick",
  "1935": "Freekick",
  "1936": "Freekick",
};

export const MESSAGES = {
  [LANGUAGE.ENGLISH]: MESSAGES_EN,
  [LANGUAGE.ALBANIAN]: MESSAGES_ALB,
};

export const SECONDARY_DICT: Record<number, Record<string, string>> = {
  [LANGUAGE.ALBANIAN]: {},
  [LANGUAGE.ENGLISH]: {},
};

export const AWAY_COLOR = "blue";
export const HOME_COLOR = "red";
