import React, { FC } from "react";
import styled from "styled-components";
import { OverlayContainer } from "components/OverlayContainer";

export const EndOfSet: FC = ({ children }) => {
  return (
    <OverlayContainer>
      <Container>
        <Text>{children}</Text>
      </Container>
    </OverlayContainer>
  );
};

export const Container = styled.div`
  display: flex;
  margin: auto;
`;

export const Text = styled.span`
  color: #f8d5ce;
  font-size: 3vw;
  margin: auto;
`;
