import React, { FC } from "react";
import styled from "styled-components";
import { TimeoutIcon } from "assets";
import { OverlayContainer } from "components/OverlayContainer";

export const Timeout: FC = ({ children }) => {
  return (
    <OverlayContainer>
      <Container>
        <TimeoutIcon />
        <Text>{children}</Text>
      </Container>
    </OverlayContainer>
  );
};

export const Container = styled.div`
  display: flex;
  margin: auto;
  & > svg {
    margin: auto 1.5vw auto 0;
    height: 6vw;
    width: auto;
  }
`;

export const Text = styled.span`
  font-weight: bold;
  border-left: 0.7vw solid black;
  padding: 0.8vw;
  font-size: 4vw;
  display: block;
  margin: auto 0;
`;
