import {io} from 'socket.io-client';
import {getNormalizedStatusCode} from 'utils/get-normalized-status-code';
import {getSide} from 'utils/get-side';
import {getXY} from 'utils/getXY';
import {SIDE} from '_constants';

const BASE_URL = 'https://trackerapi.geniusfeeds.live';

export const getStatus = async (eventId: string) => {
    const response = await (
        await fetch(`${BASE_URL}/check/${eventId}`, {
            headers: {'X-Real-Referrer': document.referrer},
        })
    ).json();
    const [x, y] = getXY(response);
    return {
        ...response,
        nsc: getNormalizedStatusCode(response),
        side: getSide(response),
        x,
        y,
    } as MatchStatus;
};

export const getSocketConnection = (eventId: string) => {
    return io(`${BASE_URL}?event=${eventId}`, {
        forceNew: true,
        reconnectionAttempts: 9999,
        timeout: 10000,
        transports: ['websocket'],
    });
};

export const getMatchDictionary = async () => {
    const response = await (
        await fetch(`${BASE_URL}/list`, {
            headers: {
                'X-Real-Referrer': document.referrer || window.location.origin,
            },
        })
    ).json();
    return response as { body: Record<SPORT, string> };
};

export enum SPORT {
    BASKETBALL = 18,
    VOLLEYBALL = 91,
    BASEBALL = 7,
    ICE_HOCKEY = 17,
    FOOTBALL = 1,
}

export type MatchStatus<TStatus = string> = {
    sts: string;
    s: SPORT;
    tm: number;
    T1n: string;
    T2n: string;
    c: Record<string, any>;
    pc: number;
    sc?: TStatus;
    nsc?: string;
    stn?: string;
    xy?: string;
    side?: SIDE;
    hidePath?: boolean;
    x?: number;
    y?: number;
};
