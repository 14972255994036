import { FC, memo } from "react";
import { MatchStatus } from "service";
import styled from "styled-components";
import { SIDE } from "_constants";

export const FREE_KICK_EVENTS = [
  "1009",
  "1010",
  "1931",
  "1932",
  "1933",
  "1934",
  "1935",
  "1936",
];

export const FreeKickIndicator: FC<{ status: MatchStatus }> = memo(
  ({ status: { x, y, sc, nsc, side } }) => {
    if (!x || !y) return <></>;

    const goalCoords = { x: side === SIDE.HOME ? 1 : 0, y: 0.5 };

    const targetAngle =
      (Math.atan2(180 * (y - goalCoords.y), 400 * (x - goalCoords.x)) * 180) /
        Math.PI -
      270;

    return (
      <>
        <PathContainer>
          <svg width="400px" height="180px" viewBox="0 0 400 180">
            {nsc && FREE_KICK_EVENTS.includes(nsc) && (
              <TargetGroup x={x} y={y} angle={targetAngle}>
                <path
                  opacity="0.1"
                  d="m 0 0, -18.125 36.25 c 0,0,18.125,9.0625, 36.25 0"
                ></path>
                <path
                  opacity="0.08"
                  d="m 0 0, -36.875 73.75 c 0,0,36.875,18.4375, 73.75 0"
                ></path>
                <path
                  opacity="0.06"
                  d="m 0 0, -55.625 111.25 c 0,0,55.625,27.8125, 111.25 0"
                ></path>
              </TargetGroup>
            )}
          </svg>
        </PathContainer>
      </>
    );
  }
);

const PathContainer = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  left: 0;

  & > svg {
    width: 100%;
    height: 100%;
  }
`;

const TargetGroup = styled.g<{ angle: number; x: number; y: number }>`
  transform: translate(
      ${(props) => props.x * 400}px,
      ${(props) => props.y * 180}px
    )
    rotate(${(props) => props.angle}deg) scale(0.6);

  animation: fadeInFromNone 1s forwards;
  animation-duration: 1s;
  animation-delay: 3s;
  transition: transform 500ms ease;
`;
