import {ReactComponent as Court} from "./assets/court.svg";
import * as service from "../../service";
import React, {FC, memo, useCallback, useState} from "react";
import styled from "styled-components";
import {Overlay} from "../../components/Overlay";
import {LANGUAGE} from "_constants";
import {ICONS, MESSAGES, SECONDARY_DICT} from "./constants";
import {FootballStatusRenderer} from "./StatusRenderer";
import {Timer} from "./Timer";
import {Ball} from "./Ball";
import {AttackIndicator} from "./indicators/AttackIndicator";
import {ThrowInIndicator} from "./indicators/ThrowInIndicator";
import {CornerIndicator} from "./indicators/CornerIndicator";
import {FreeKickIndicator} from "./indicators/FreeKickIndicator";
import {GoalKickIndicator} from "./indicators/GoalKickIndicator";
import {OffsideIndicator} from "./indicators/OffsideIndicator";
import {adjustBallPosition} from "./adjust-ball-position";
import {ScoreIndicator} from "./indicators/ScoreIndicator";
import {ShotOnOrOffTarget} from "./indicators/ShotOnOrOffTarget";
import {Stats} from "./Stats";
import courtSvg from "assets/court.svg";
import activeCourtSvg from "assets/court-active.svg";
import {Carousel} from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

export type FootballProps = {
    status: service.MatchStatus;
    language?: LANGUAGE;
};

export enum ActivePage {
    Game = 0,
    Stats = 1,
}

export const Football: FC<FootballProps> = memo(
    ({status, language = LANGUAGE.ENGLISH}) => {
        adjustBallPosition(status);

        const [selectedPage, setSelectedPage] = useState<ActivePage>(ActivePage.Game);

        const messageGenerator = useCallback(
            ({
                 language,
                 normalizedStatusCode,
                 statusCode,
                 statusMessage,
             }: {
                language: LANGUAGE;
                normalizedStatusCode?: string;
                statusCode?: string;
                statusMessage?: string;
            }) => {
                if (!normalizedStatusCode) return undefined;
                const langDict = MESSAGES[language];
                if (!langDict) return undefined;
                let message = langDict[normalizedStatusCode];

                if (normalizedStatusCode === "1280") message = statusMessage!;

                const secondaryDict = SECONDARY_DICT[language];

                if (secondaryDict && secondaryDict[message])
                    message = secondaryDict[message];

                const icon = ICONS[normalizedStatusCode];
                return {message, icon};
            },
            []
        );
        return (
            <>
                <div style={{
                    display: "flex",
                    justifyContent: "center",
                    paddingBottom: "1rem",
                    gap: "2rem",
                }}>
                    <a href="#" onClick={() => {
                        setSelectedPage(ActivePage.Game);
                    }}>
                        <img src={selectedPage === ActivePage.Game ? activeCourtSvg : courtSvg}
                             alt="court logo active"
                             style={{
                                 minHeight: "1rem",
                                 maxHeight: "2rem"
                             }}
                        />
                    </a>
                    <a href="#" style={{
                        textDecoration: selectedPage === ActivePage.Stats ? "solid underline white 3px" : "none",
                        color: "#ffffff",
                        opacity: selectedPage === ActivePage.Stats ? 1 : .6,
                        fontWeight: selectedPage === ActivePage.Stats ? "bold" : "normal",
                    }} onClick={() => {
                        setSelectedPage(ActivePage.Stats);
                    }}>
                        Stats
                    </a>
                </div>

                <Carousel
                    showThumbs={false}
                    selectedItem={selectedPage}
                    autoPlay={false}
                    emulateTouch
                    showStatus={false}
                    showArrows={false}
                    showIndicators={false}
                    onChange={(e) => {
                        setSelectedPage(e);
                    }}
                >
                    <div>
                        <div style={{position: "relative"}}>
                            <StyledCourt className={`sc-${status.sc} `}/>
                            <Overlay
                                status={status}
                                messageGenerator={messageGenerator}
                                language={language}
                                StatusRenderer={FootballStatusRenderer}
                            >
                                <Timer status={status}/>
                                <AttackIndicator status={status}/>
                                <ThrowInIndicator status={status}/>
                                <CornerIndicator status={status}/>
                                <FreeKickIndicator status={status}/>
                                <GoalKickIndicator status={status}/>
                                <OffsideIndicator status={status}/>
                                <ScoreIndicator status={status}/>
                                <ShotOnOrOffTarget status={status}/>
                                <Ball status={status}/>
                            </Overlay>
                        </div>
                    </div>
                    <div>
                        <Stats status={status} language={language}/>
                    </div>
                </Carousel>
            </>
        );
    }
);

const StyledCourt = styled(Court)`
    width: 100%;
    height: 100%;

    -webkit-user-select: none;
    -webkit-user-drag: none;

    @keyframes blinker {
        50% {
            opacity: 0;
        }
    }

    &.sc-21295 #ml17-Possession2 {
        opacity: 0.4 !important;
    }

    &.sc-11295 #ml17-Possession1 {
        opacity: 0.4 !important;
    }
`;
