import { LANGUAGE } from "_constants";

const MESSAGES_EN: Record<string, string> = {
  "1248": "Serve",
  "1249": "Rally",
  "1250": "Point Scored",
  "1254": "End of Set",
  "1260": "Point Scored",
  "1252": "Service fault", //
  "1253": "Ace", //
  "1255": "Timeout",
  "1256": "Technical Timeout",
  "1257": "Last Six",
  "1258": "Serve Points Won",
  "1259": "Current Streak",
  "1261": "End Of Match", //
  "9999": "Served",
};

const MESSAGES_ALB: Record<string, string> = {
  "1248": "Serve",
  "1249": "Rally",
  "1250": "Point Scored",
  "1254": "End of Set",
  "1260": "Point Scored",
  "1252": "Service fault",
  "1253": "Ace",
  "1255": "Timeout",
  "1256": "Technical Timeout",
  "1257": "Last Six",
  "1258": "Serve Points Won",
  "1259": "Current Streak",
  "1261": "End Of Match",
  "9999": "Served",
};

export const MESSAGES = {
  [LANGUAGE.ENGLISH]: MESSAGES_EN,
  [LANGUAGE.ALBANIAN]: MESSAGES_ALB,
};
