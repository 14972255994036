import { FC } from "react";
import styled from "styled-components";
import { AWAY_COLOR, HOME_COLOR } from "../constants";

export const LineChart: FC<{ homeValue: number; awayValue: number }> = ({
  homeValue,
  awayValue,
}) => {
  return (
    <Container>
      <Track />
      <Progress progress={(awayValue / (homeValue + awayValue)) * 100} />
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  height: 0.6vw;
  width: 100%;
  margin: auto 0;
`;

const Track = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: ${HOME_COLOR};
  border-radius: 5vw;
`;

const Progress = styled.div<{ progress: number }>`
  position: absolute;
  right: 0;
  top: 0;
  width: ${(props) => props.progress}%;
  height: 100%;
  background: ${AWAY_COLOR};
  border-radius: 5vw;
`;
