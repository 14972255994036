import { FC, memo, useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { CountdownTimerContext } from "../../components";
import { OverlayContainer } from "../../components/OverlayContainer";
import { MatchStatus } from "../../service";
import { AWAY_COLOR, HOME_COLOR } from "./constants";

const TIMER_PAUSE_EVENTS: string[] = ["1015", "1019", "1021", "1022", "1017"];

export const Timer: FC<{ status: MatchStatus }> = memo(
  ({
    status: {
      nsc,
      c: {
        GOAL: [homeScore, awayScore],
      },
        tm,
    },
  }) => {
    const [isExtraTime, setIsExtraTime] = useState(false);

    const {
      minutes,
      seconds,
      pauseTimer,
      continueTimer,
      setDirection,
    } = useContext(CountdownTimerContext);

    useEffect(() => {
      if (tm > 0) {
        setDirection(+1);
      } else {
        setDirection(-1);
      }

    }, [setDirection, continueTimer]);

    useEffect(() => {
      if (nsc && TIMER_PAUSE_EVENTS.includes(nsc)) pauseTimer();
      else continueTimer();
    }, [nsc, pauseTimer, continueTimer]);

    useEffect(() => {
      let timeout: NodeJS.Timeout;
      if (nsc === "1026") {
        setIsExtraTime(true);
      } else if (nsc && ["1015", "1016", "1017", "1018"].includes(nsc)) {
        setIsExtraTime(false);
      }

      return () => {
        if (timeout) clearTimeout(timeout);
      };
    }, [nsc]);

    if (nsc && TIMER_PAUSE_EVENTS.includes(nsc)) return <></>;
    return (
      <OverlayContainer>
        <TimerContainer>
          <TimerInnerContainer>
            <div style={{ margin: "0 auto", position: "relative" }}>
              <span>{minutes}</span>
              <span>:</span>
              <span>{seconds}</span>
            </div>
            <div style={{ margin: "0 auto", position: "relative" }}>
              <span
                style={{
                  borderLeft: `1.5vw solid ${HOME_COLOR}`,
                  paddingLeft: "0.5vw",
                  height: "0.5vw",
                  fontSize: "1.5vw",
                  lineHeight: "1.5vw",
                }}
              >
                {homeScore}
              </span>
              <span> - </span>
              <span
                style={{
                  borderRight: `1.5vw solid ${AWAY_COLOR}`,
                  paddingRight: "0.5vw",
                  height: "0.5vw",
                  fontSize: "1.5vw",
                  lineHeight: "1.5vw",
                }}
              >
                {awayScore}
              </span>
            </div>
          </TimerInnerContainer>
          <div style={{ position: "relative" }}>
            {isExtraTime && (
              <div className="extra-time">
                <span>Injury Time</span>
              </div>
            )}
          </div>
        </TimerContainer>
      </OverlayContainer>
    );
  }
);

const TimerContainer = styled.div`
  margin: 0 auto auto auto;

  color: #77c4a6;
  margin-top: 0.45vw;

  display: flex;
  font-size: 2vw;

  position: relative;
  & .extra-time {
    position: absolute;
    left: 0.5vw;
    padding: 0.4vw 1vw;
    border-radius: 0.5vw;
    white-space: nowrap;
    background: rgba(40, 79, 52, 0.8);
    font-size: 1.5vw;
  }
`;

const TimerInnerContainer = styled.div`
  background: rgba(40, 79, 52, 0.5);
  display: flex;
  flex-direction: column;
  padding: 0.4vw 1vw;
  border-radius: 0.5vw;
`;
