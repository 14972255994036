import { LANGUAGE } from "_constants";
import { reverseDictionary } from "../../utils/reverse-dictionary";

export enum EVENTS {
  "2 Points" = "1074",
  "3 Points" = "1075",
  "Possession" = "1077",
  "Free Throw" = "1078",
  "Free Throw Scored" = "1079",
  "Free Throw Missed" = "1080",
  "Time Out" = "1081",
  "Foul" = "1086",
  "End Of Quarter" = "1082",
  "End Of Half" = "1083",
  "End Of Match" = "1084",
  "Overtime" = "1085",
  "Quarter 1" = "1087",
  "Quarter 2" = "1088",
  "Quarter 3" = "1089",
  "Quarter 4" = "1090",
  "Half 1" = "1091",
  "Half 2" = "1092",
}

export enum EVENTS_ALB {
  "2 Pikësh" = "1074",
  "3 Pikësh" = "1075",
  "Zotërim të topit" = "1077",
  "Gjuajtje e Lirë" = "1078",
  "Gjuajtja e Lirë u shënua" = "1079",
  "Gjuajtja e Lirë nuk u shënua" = "1080",
  "Mbaroi koha" = "1081",
  "Faull" = "1086",
  "Fundi i Periodës" = "1082",
  "Fundi i Pjesës Parë" = "1083",
  "Fundi i Ndeshjes" = "1084",
  "Koha Shtesë" = "1085",
  "Perioda 1" = "1087",
  "Perioda 2" = "1088",
  "Perioda 3" = "1089",
  "Perioda 4" = "1090",
  "Pjesa Parë" = "1091",
  "Pjesa Dytë" = "1092",
}

export const MESSAGES = {
  [LANGUAGE.ENGLISH]: reverseDictionary(EVENTS),
  [LANGUAGE.ALBANIAN]: reverseDictionary(EVENTS_ALB),
};

export const TIMER_PAUSE_EVENTS = [
  EVENTS["End Of Quarter"],
  EVENTS["End Of Half"],
  EVENTS["End Of Match"],
  EVENTS["Free Throw"],
  EVENTS["Time Out"],
  EVENTS["Foul"],
];

export const TIMER_CONTINUE_EVENTS = [
  EVENTS["Possession"],
  EVENTS["2 Points"],
  EVENTS["3 Points"],
];
