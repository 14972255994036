import { FC, memo, useLayoutEffect, useMemo, useRef } from "react";
import styled from "styled-components";
//@ts-ignore
import Gauge from "svg-gauge";
import { AWAY_COLOR, HOME_COLOR } from "../constants";

type ChartProps = {
  homeValue: number;
  awayValue: number;
  title: string;
};

export const Chart: FC<ChartProps> = memo(({ homeValue, title, awayValue }) => {
  const gaugeContainer = useRef<HTMLDivElement>(null);
  const gaugeRef = useRef<any>();
  const awayPercentage = (awayValue / (homeValue + awayValue)) * 100;

  useLayoutEffect(() => {
    if (!gaugeRef.current)
      gaugeRef.current = Gauge(gaugeContainer.current, {
        max: 100,
        value: awayPercentage,
        dialStartAngle: -90,
        dialEndAngle: -90.001,
        showValue: false,
      });
    else {
      gaugeRef.current!.setValueAnimated(awayPercentage);
    }
  }, [awayPercentage]);

  return (
    <Container
      style={{
        //@ts-ignore
        "--rotation-value": -((awayPercentage / 100) * 360 - 180) / 2 + "deg",
      }}
    >
      <span className="title">{title}</span>
      <Row>
        <Value>{homeValue}</Value>
        <GaugeContainer ref={gaugeContainer}></GaugeContainer>
        <Value>{awayValue}</Value>
      </Row>
    </Container>
  );
});

const Container = styled.div`
  display: flex;
  flex-direction: column;
  & > .title {
    margin: 0 auto;
    color: gray;
    font-size: 2vw;
    font-weight: bold;
    margin-bottom: 1vw;
  }
`;

const GaugeContainer = styled.div`
  width: 15vw;
  margin: 0 auto;
  & > svg {
    transform: rotate(var(--rotation-value));
  }

  & path {
    stroke: ${HOME_COLOR};
    stroke-width: 0.6vw;
  }

  & path.value {
    stroke: ${AWAY_COLOR};
    stroke-linecap: round;
  }
`;

const Row = styled.div`
  display: flex;
`;

const Value = styled.span`
  margin: auto 1vw auto 1vw;
  font-size: 3vw;
  color: gray;
`;
