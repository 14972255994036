import { FC, useMemo } from "react";
import styled from "styled-components";
import { FootballProps } from "..";
import { CardsAndCorners } from "./CardsAndCorners";
import { Chart } from "./Chart";

const extract = (splitStatus: any, n: number) =>
  splitStatus[n].split("=")[1].split(":").map(Number);

export const Stats: FC<FootballProps> = ({ language, status }) => {
  const data = useMemo(() => {
    const splitStatus = status.sts.split("|");
    const onTarget = extract(splitStatus, 0);
    const offTarget = extract(splitStatus, 1);
    const attacks = extract(splitStatus, 2);
    const dangerousAttacks = extract(splitStatus, 3);
    const possession = extract(splitStatus, 6);
    return {
      onTarget,
      offTarget,
      attacks,
      dangerousAttacks,
      possession,
    };
  }, [status]);

  return (
    <StatsContainer>
      <Row>
        <Chart
          homeValue={data?.attacks[0]}
          awayValue={data?.attacks[1]}
          title="Attacks"
        />
        <Chart
          homeValue={data?.dangerousAttacks[0]}
          awayValue={data?.dangerousAttacks[1]}
          title="Dangerous Attacks"
        />
        <Chart
          homeValue={data?.possession[0]}
          awayValue={data?.possession[1]}
          title="Possession %"
        />
      </Row>
      <Row>
        <CardsAndCorners
          redCards={status.c.RED_CARD}
          yellowCards={status.c.YELLOW_CARD}
          onTarget={data?.onTarget}
          offTarget={data?.offTarget}
          corners={status.c.CORNER}
        />
      </Row>
    </StatsContainer>
  );
};

const StatsContainer = styled.div`
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  padding: 0 2vw;
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  flex-grow: 1;
`;
