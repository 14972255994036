import { ReactComponent as Court } from "./assets/court.svg";
import * as service from "../../service";
import React, { FC, memo, useCallback, useContext, useEffect } from "react";
import styled from "styled-components";
import { Overlay } from "../../components/Overlay";
import { LANGUAGE } from "_constants";
import { ICONS, MESSAGES, SECONDARY_DICT } from "./constants";
import { VolleyballStatusRenderer } from "./StatusRenderer";
import { CountdownTimerContext } from "components";
import { Timer } from "./Timer";
import { LivePuck } from "./Puck";

export type IceHockeyProps = {
  status: service.MatchStatus;
  language?: LANGUAGE;
};

export const IceHockey: FC<IceHockeyProps> = memo(
  ({ status, language = LANGUAGE.ENGLISH }) => {
    const { setDirection, continueTimer } = useContext(CountdownTimerContext);

    useEffect(() => {
      setDirection(+1);
      continueTimer();
    }, [setDirection, continueTimer]);

    const messageGenerator = useCallback(
      ({
        language,
        normalizedStatusCode,
        statusCode,
        statusMessage,
      }: {
        language: LANGUAGE;
        normalizedStatusCode?: string;
        statusCode?: string;
        statusMessage?: string;
      }) => {
        if (!normalizedStatusCode) return undefined;
        const langDict = MESSAGES[language];
        if (!langDict) return undefined;
        let message = langDict[normalizedStatusCode];

        if (normalizedStatusCode === "1280") message = statusMessage!;

        const secondaryDict = SECONDARY_DICT[language];

        if (secondaryDict && secondaryDict[message])
          message = secondaryDict[message];

        const icon = ICONS[normalizedStatusCode];
        return { message, icon };
      },
      []
    );
    return (
      <>
        <StyledCourt className={`sc-${status.sc} `} />
        <Overlay
          status={status}
          messageGenerator={messageGenerator}
          language={language}
          StatusRenderer={VolleyballStatusRenderer}
        >
          <Timer status={status} />
          <LivePuck status={status} />
        </Overlay>
      </>
    );
  }
);

const StyledCourt = styled(Court)`
  width: 100%;
  height: 100%;

  @keyframes blinker {
    50% {
      opacity: 0;
    }
  }

  &.sc-21295 #ml17-Possession2 {
    opacity: 0.4 !important;
  }
  &.sc-11295 #ml17-Possession1 {
    opacity: 0.4 !important;
  }
`;
