import { FC, memo } from "react";
import { MatchStatus } from "service";
import styled from "styled-components";
import { SIDE } from "_constants";

export const AttackIndicator: FC<{ status: MatchStatus }> = memo(
  ({ status: { x, y, sc, nsc, side } }) => {
    if (!x || !y) return <></>;

    return (
      <>
        <PathContainer>
          <svg width="400px" height="180px" viewBox="0 0 400 180">
            <defs>
              <linearGradient id="gradient" x1="0" y1="0.5" x2="1" y2="0.5">
                <stop offset="0" stopColor="rgba(230,73,9,0.1)" />
                <stop offset="0.5" stopColor="rgba(230,73,9,0.1)" />

                <stop
                  offset="1"
                  stopColor={`rgba(230,73,9,${
                    0.3 +
                    0.5 *
                      Math.max((x - 0.6) * (side === SIDE.HOME ? 1 : -1), 0) *
                      2
                  })`}
                />
              </linearGradient>
            </defs>

            {(nsc === "1000" || nsc === "1001") && (
              <AttackPath
                data-side={side}
                x={x}
                style={{ opacity: nsc === "1001" ? 0.7 : "unset" }}
                fill={nsc === "1000" ? "url(#gradient)" : "#1d551d"}
                d="M2,2 390, 2 390, 2 C390, 1.4 400, 28 400, 28 C400, 29 400, 30 400, 31 L390, 61 L400, 88 C400, 89 400, 90 400, 91 L390, 121 L400, 146 C400, 147 400, 150 400, 151 L390, 178 C390, 178 2.0, 178 2.0, 178 L2, 2 Z"
              />
            )}

            {nsc === "1002" && (
              <rect
                x={side === SIDE.HOME ? 2 : 200}
                y="2"
                width="198"
                height="176"
                fill="#1d551d"
                style={{ opacity: "0.7" }}
              />
            )}
          </svg>
        </PathContainer>
      </>
    );
  }
);

const PathContainer = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  left: 0;

  & > svg {
    width: 100%;
    height: 100%;
  }
`;

const AttackPath = styled.path<{ x: number }>`
  transform: translateX(${(props) => props.x * 400 - 400 + 17}px);
  transform-origin: 50% 50%;
  transition: transform 1s ease;

  &[data-side="2"] {
    transform: translateX(${(props) => props.x * 400 - 17}px) rotate(180deg);
  }
`;
