import { FC } from "react";
import styled from "styled-components";

export const TeamName: FC<{
  name: string;
  isAway: boolean;
  className?: string;
  served?: boolean;
  servedText?: string;
}> = ({ name, isAway, className, served, servedText }) => (
  <MainContainer isAway={isAway}>
    {served && <ServedText>{servedText}</ServedText>}
    <Container isAway={isAway} className={className}>
      <Text isAway={isAway}>{name}</Text>
    </Container>
  </MainContainer>
);

const MainContainer = styled.div<{ isAway?: boolean }>`
  display: flex;
  flex-direction: column;
  text-align: ${(props) => (props.isAway ? "right" : "left")};
  padding: 0 1.8vw;
  margin-top: auto;
`;

const Container = styled.div<{ isAway?: boolean }>`
  width: 30vw;
  min-height: 3.5vw;

  color: white;
  border-width: 0.6vw;
  border-right-style: ${(props) => (props.isAway ? "solid" : "unset")};
  border-left-style: ${(props) => (props.isAway ? "unset" : "solid")};
  border-color: ${(props) => (props.isAway ? "black" : "white")};

  display: flex;
  flex-direction: column;
  padding: 0 2vw;
`;

const Text = styled.span<{ isAway?: boolean }>`
  font-size: 2.5vw;
  line-height: 1;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: ${(props) => (props.isAway ? "auto" : "unset")};
`;

const ServedText = styled.span`
  color: #efbbb2;
  font-size: 2vw;
  margin-bottom: 0.6vw;
  margin: 0 2.6vw;
`;
