import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { getMatchDictionary, SPORT } from 'service';
import styled from 'styled-components';

export const MatchSelector: FC = () => {
  const [dictionary, setDictionary] = useState({});

  const fetched = useRef(false);
  const fetchDictionary = useCallback(async () => {
    if (fetched.current === true) return;
    fetched.current = true;
    const { body } = await getMatchDictionary();
    setDictionary(body);
  }, []);

  useEffect(() => {
    fetchDictionary();
  }, [fetchDictionary]);

  // const [, clientId] = window.location.pathname.split('/') as [undefined, string, undefined];

  return (
    <Container>
      <SportContainer>
        <SportName>Basketball</SportName>
        {
          //@ts-expect-error
          dictionary[SPORT.BASKETBALL]?.map((match) => (
            <Link href={`/${match}`}>{match}</Link>
          ))
        }
      </SportContainer>
      <SportContainer>
        <SportName>Volleyball</SportName>
        {
          //@ts-expect-error
          dictionary[SPORT.VOLLEYBALL]?.map((match) => (
            <Link href={`/${match}`}>{match}</Link>
          ))
        }
      </SportContainer>

      <SportContainer>
        <SportName>Baseball</SportName>
        {
          //@ts-expect-error
          dictionary[SPORT.BASEBALL]?.map((match) => (
            <Link href={`/${match}`}>{match}</Link>
          ))
        }
      </SportContainer>

      <SportContainer>
        <SportName>Ice Hockey</SportName>
        {
          //@ts-expect-error
          dictionary[SPORT.ICE_HOCKEY]?.map((match) => (
            <Link href={`/${match}`}>{match}</Link>
          ))
        }
      </SportContainer>

      <SportContainer>
        <SportName>Football</SportName>
        {
          //@ts-expect-error
          dictionary[SPORT.FOOTBALL]?.map((match) => (
            <Link href={`/${match}`}>{match}</Link>
          ))
        }
      </SportContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
`;

const SportContainer = styled(Container)`
  flex-direction: column;
  margin-right: 40px;
`;

const SportName = styled.span`
  display: block;
  font-weight: bold;
  margin-bottom: 15px;
`;

const Link = styled.a`
  color: gray;
`;
