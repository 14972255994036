import { ReactComponent as Court } from "./assets/court.svg";
import * as service from "../../service";
import React, { FC, memo, useMemo } from "react";
import { TimerController } from "../../components";
import styled from "styled-components";
import { TIMER_CONTINUE_EVENTS, TIMER_PAUSE_EVENTS } from "./constants";
import { Timer } from "./Timer";
import { processStatus } from "./utils";
import { StatusMessage } from "./StatusMessage";
import { LANGUAGE } from "_constants";

export type BasketballProps = {
  status: service.MatchStatus;
  language?: LANGUAGE;
};

export const Basketball: FC<BasketballProps> = memo(
  ({ status, language = LANGUAGE.ENGLISH }) => {
    const {
      isAway,
      teamName,
      message,
      description,
      icon,
      normalizedStatusCode,
    } = useMemo(() => processStatus(status, language), [status, language]);

    return (
      <div className="d-flex p-relative">
        <StyledCourt className={`sc-${status.sc} `} />
        <Timer status={status} />
        {message && (
          <StatusMessage
            team={teamName}
            isAway={isAway}
            title={message}
            description={description}
            Icon={icon}
            normalizedCode={normalizedStatusCode}
          />
        )}
        <TimerController
          event={normalizedStatusCode}
          eventsContinue={TIMER_CONTINUE_EVENTS}
          eventsPause={TIMER_PAUSE_EVENTS}
        />
      </div>
    );
  }
);

const StyledCourt = styled(Court)`
  width: 100%;
  height: 100%;
  &.sc-11077 {
    #ml18-Possession1 {
      opacity: 0.4 !important;
    }
  }

  &.sc-21077 {
    *#ml18-Possession2 {
      opacity: 0.4 !important;
    }
  }

  &.sc-21074 {
    #ml18-threePointLine2 {
      opacity: 0.4;
      fill: #cc6029;
    }
  }

  &.sc-11074 {
    #ml18-threePointLine1 {
      opacity: 0.4;
      fill: #cc6029;
    }
  }

  &.sc-11075 {
    #ml18-1Score3 {
      opacity: 0.4;
    }
  }

  &.sc-21075 {
    #ml18-2Score3 {
      opacity: 0.4;
    }
  }
`;
