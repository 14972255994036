import { FC, useMemo } from "react";
import styled from "styled-components";
import { OverlayContainer } from "components/OverlayContainer";

export const Score: FC<{ statusText: string }> = ({ statusText }) => {
  const points = useMemo(() => {
    return statusText
      .split("|")
      .find((part) => part.startsWith("INFO="))
      ?.substring(5)
      ?.split(":");
  }, [statusText]);

  if (!points || !points[0] || !points[1]) return <></>;

  return (
    <OverlayContainer>
      <Container>
        <Home>
          <Number>{points[0]}</Number>
        </Home>
        <Away>
          <Number>{points[1]}</Number>
        </Away>
      </Container>
    </OverlayContainer>
  );
};

const Container = styled.div`
  position: relative;
  display: flex;
  margin: auto;
`;

const Home = styled.div`
  padding-bottom: 0.4vw;
  border-bottom: 0.7vw solid white;
  margin: 0 2vw;
  padding: 0 1.2vw;
`;
const Away = styled(Home)`
  border-bottom: 0.7vw solid black;
`;

const Number = styled.span`
  font-size: 5vw;
  color: #fce04e;
  font-weight: bold;
`;
