import { OverlayContainer } from "components";
import React, { FC, memo, useCallback, useEffect, useState } from "react";
import { MatchStatus } from "service";
import styled from "styled-components";
import { SIDE } from "_constants";
import { AWAY_COLOR, HOME_COLOR } from "../constants";
import { CSSTransition, SwitchTransition } from "react-transition-group";

const ANIMATION_DURATION = 500;

export const ScoreIndicator: FC<{ status: MatchStatus }> = memo(
  ({
    status: {
      x,
      y,
      sc,
      nsc,
      side,
      c: { GOAL: _GOAL },
    },
  }) => {
    const [_homeScore, _awayScore] = _GOAL as [number, number];

    const [
      {
        score: [homeScore, awayScore],
        toggle,
        isActive,
      },
      setState,
    ] = useState({
      score: [_homeScore, _awayScore],
      toggle: false,
      isActive: false,
    });

    const activate = useCallback(() => {
      setState((state) => ({ ...state, isActive: true, toggle: false }));
      setTimeout(() => {
        setState((state) => ({ ...state, toggle: true }));
      }, ANIMATION_DURATION);
    }, []);

    useEffect(() => {
      if (homeScore === _homeScore && awayScore === _awayScore) return;
      if (nsc !== "1003" || isActive || toggle) return;
      activate();
      setTimeout(() => {
        setState((state) => ({
          ...state,
          score: [_homeScore, _awayScore],
        }));
      }, 2 * ANIMATION_DURATION);
    }, [
      homeScore,
      awayScore,
      _awayScore,
      _homeScore,
      activate,
      nsc,
      isActive,
      toggle,
    ]);

    useEffect(() => {
      if (nsc !== "1003")
        setState((state) => ({
          ...state,
          isActive: false,
          toggle: false,
          score: [_homeScore, awayScore],
        }));
    }, [nsc, _homeScore, awayScore]);

    if (!isActive) return <></>;

    return (
      <OverlayContainer>
        <InnerContainer>
          <div
            style={{
              display: "flex",
              margin: "auto",
            }}
          >
            <ScoreWrapper side={SIDE.HOME} className={toggle ? "enter" : ""}>
              <Score side={SIDE.HOME} score={homeScore} />
            </ScoreWrapper>
            <ScoreWrapper side={SIDE.AWAY} className={toggle ? "enter" : ""}>
              <Score side={SIDE.AWAY} score={awayScore} />
            </ScoreWrapper>
          </div>
        </InnerContainer>
      </OverlayContainer>
    );
  }
);

const ScoreWrapper = styled.div<{ side: SIDE }>`
  transform: translateX(
    ${(props) => (props.side === SIDE.HOME ? -1 : 1) * 6}vw
  );

  &.enter {
    transform: translateX(0);
    transition: transform ${ANIMATION_DURATION}ms ease;
  }
`;

const InnerContainer = styled.div`
  display: flex;
  margin: auto;
  width: 40vw;
  height: 10vw;
  overflow: hidden;
`;

const Score: FC<{ score?: number; side: SIDE; className?: string }> = memo(
  ({ score: _score = 0, side, className }) => {
    const [score, setScore] = useState<number | undefined>(_score);

    useEffect(() => {
      setScore(_score);
    }, [_score]);

    return (
      <SwitchTransition mode="out-in">
        <CSSTransition
          key={score}
          addEndListener={(node, done) =>
            node.addEventListener("transitionend", done, false)
          }
          classNames="score"
        >
          <ScoreContainer side={side} className={className}>
            {score}
          </ScoreContainer>
        </CSSTransition>
      </SwitchTransition>
    );
  }
);
const ScoreContainer = styled.div<{ side: SIDE }>`
  font-size: 6vw;
  border-bottom: 0.4vw solid
    ${(props) => (props.side === SIDE.HOME ? HOME_COLOR : AWAY_COLOR)};
  margin: 0 2vw;
  padding: 1vw 2vw 0 2vw;
  line-height: 0.95;
  color: #f2d847;

  &.score-enter {
    transform: translateX(0) translateY(11vw);
  }

  &.score-enter-active {
    transform: translateX(0) translateY(0);
  }

  &.score-exit {
    transform: translateX(0) translateY(0);
  }

  &.score-exit-active {
    transform: translateX(0) translateY(-11vw);
  }

  transition: transform ${ANIMATION_DURATION}ms ease;
`;
