import { FC, useMemo } from "react";
import styled from "styled-components";
import { OverlayContainer } from "components/OverlayContainer";
import { ReactComponent as Ball } from "../assets/ball.svg";

type LastSixPointsProps = {
  statusText: string;
};

export const LastSixPoints: FC<LastSixPointsProps> = ({ statusText }) => {
  const points = useMemo(() => {
    const parts = statusText.split("|");

    const last6 = parts.find((part) => part.startsWith("INFO="));
    if (last6) {
      return last6
        .substring(7)
        .split(",")
        .map((x) => x.split(":"));
    } else return undefined;
  }, [statusText]);

  if (!points) return <></>;
  return (
    <OverlayContainer>
      <Container>
        {points.map(([score, side]) => (
          <PointContainer isAway={side === "2"} key={score}>
            <Score>{score}</Score>
            <Ball />
          </PointContainer>
        ))}
      </Container>
    </OverlayContainer>
  );
};

const Container = styled.div`
  margin: auto;
  display: flex;
`;

const Score = styled.span`
  font-size: 3vw;
  font-weight: bold;
  margin-left: auto;
  margin-right: auto;
`;

const PointContainer = styled.div<{ isAway?: boolean }>`
  display: flex;
  flex-direction: column;
  border-bottom-width: 0.7vw;
  border-bottom-style: solid;
  border-bottom-color: ${(props) => (props.isAway ? "black" : "white")};
  margin-right: 3vw;

  &:last-child {
    margin-right: 0;
  }

  & > svg {
    padding: 1vw 0;
    width: 5vw;
    height: auto;
  }
`;
