import { MatchStatus } from "service";
import { SIDE } from "_constants";

export const getSide = (status: MatchStatus) => {
  const sc = status.sc;

  if (!sc || sc.length === 4) return SIDE.NO_SIDE;
  else if (sc.length === 5) {
    if (sc.startsWith("2")) return SIDE.AWAY;
    else if (sc?.startsWith("1")) return SIDE.HOME;
    else if (sc?.startsWith("0")) return SIDE.NO_SIDE;
  }

  return SIDE.NO_SIDE;
};
